<template>
    <div class="flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-black">
        <h1 class="text-6xl font-bold text-green-600 mb-4">404</h1>
        <p class="text-xl text-gray-700 dark:text-gray-300 mb-8">Oops! Page not found.</p>
        <router-link to="/" class="bg-green-600 text-white px-6 py-3 rounded-lg hover:bg-green-500 transition-colors">
            Go Home
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'NotFound'
}
</script>