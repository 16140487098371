// src/router/index.js

import { createRouter, createWebHistory } from 'vue-router'
import YuginAI from '../src/components/YuginAI.vue'
import AgentsInfoView from './components/AgentsView.vue'
import NotFound from './components/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: YuginAI
  },
  {
    path: '/conversation/:id',
    name: 'Conversation',
    component: YuginAI
  },
  {
    path: '/agents',
    name: 'AI agents',
    component : AgentsInfoView,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router