import axios from 'axios';

// Decode JWT token to check expiration
function isTokenExpired(token) {
    if (!token) return true;
    try {
        const payloadBase64 = token.split('.')[1];
        const decodedJson = atob(payloadBase64);
        const decoded = JSON.parse(decodedJson);
        const exp = decoded.exp * 1000;  // Convert to milliseconds
        const now = new Date();
        return now.getTime() > exp;
    } catch (error) {
        console.error('Failed to decode token:', error);
        return true;  // Assume expired if there's an error decoding
    }
}

// Refresh the token using the refresh token
async function refreshToken(refreshToken) {
    try {
        const response = await axios.post('https://api.yugin.co/token/refresh/', {
            refresh: refreshToken
        });
        return response.data;
    } catch (error) {
        console.error('Error refreshing token:', error);
        throw error;
    }
}

// Get or refresh the token if needed
export async function getValidAuthToken() {
    const authToken = localStorage.getItem('authToken');
    const refresh_token = localStorage.getItem('refreshToken');
    
    // Check if the current token is valid, and if not, try to refresh it
    if (isTokenExpired(authToken)) {
        if (refresh_token) {
            try {
                const newTokens = await refreshToken(refresh_token);
                localStorage.setItem('authToken', newTokens.access);
                localStorage.setItem('refreshToken', newTokens.refresh);
                return newTokens.access;
            } catch (error) {
                console.error('Unable to refresh token, clearing tokens:', error);
                localStorage.removeItem('authToken');
                localStorage.removeItem('refreshToken');
                // Redirect to login or handle as per app's flow
                return null;  // No valid token available
            }
        } else {
            // Handle redirect to login or similar action
            return null;
        }
    }
    return authToken;
}
