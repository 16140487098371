<template>
    <svg v-if="loading" class="animate-spin" :fill="color" height="24px" width="24px" viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
        </path>
    </svg>
    <svg v-else :fill="color" height="24px" width="24px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 226.451 226.451" xml:space="preserve">
        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 226.451 226.451" xml:space="preserve">
        <g>
            <g>
                <g>
                    <path
                        d="M78.229,49.408c1.777,0,3.349-1.138,3.908-2.822l4.467-13.506l13.506-4.467c1.684-0.559,2.822-2.131,2.822-3.908 c0-1.777-1.138-3.349-2.822-3.908L86.604,16.33L82.137,2.822C81.578,1.138,80.006,0,78.229,0s-3.349,1.138-3.908,2.822 l-4.467,13.506l-13.506,4.467c-1.684,0.559-2.822,2.131-2.822,3.908s1.138,3.349,2.822,3.908l13.506,4.467l4.467,13.506 C74.879,48.27,76.452,49.408,78.229,49.408z M74.405,23.494c1.234-0.41,2.204-1.379,2.614-2.614l1.21-3.655l1.21,3.655 c0.41,1.234,1.379,2.204,2.614,2.614l3.655,1.21l-3.655,1.21c-1.234,0.41-2.204,1.379-2.614,2.614l-1.21,3.655l-1.21-3.655 c-0.41-1.234-1.379-2.204-2.614-2.614l-3.655-1.21L74.405,23.494z">
                    </path>
                    <path
                        d="M58.937,57.851l-13.506-4.467l-4.467-13.506c-0.559-1.684-2.131-2.822-3.908-2.822c-1.777,0-3.349,1.138-3.908,2.822 L28.68,53.384l-13.506,4.467c-1.684,0.559-2.822,2.131-2.822,3.908c0,1.777,1.138,3.349,2.822,3.908l13.506,4.467l4.467,13.506 c0.559,1.684,2.131,2.822,3.908,2.822c1.777,0,3.349-1.138,3.908-2.822l4.467-13.506l13.506-4.467 c1.684-0.559,2.822-2.131,2.822-3.908C61.758,59.982,60.622,58.41,58.937,57.851z M40.879,62.97 c-1.234,0.41-2.204,1.379-2.614,2.614l-1.21,3.655l-1.21-3.655c-0.41-1.234-1.379-2.204-2.614-2.614l-3.655-1.21l3.655-1.21 c1.234-0.41,2.204-1.379,2.614-2.614l1.21-3.655l1.21,3.655c0.41,1.234,1.379,2.204,2.614,2.614l3.655,1.21L40.879,62.97z">
                    </path>
                    <path
                        d="M120.697,197.84l-13.506-4.467l-4.467-13.506c-0.559-1.684-2.131-2.822-3.908-2.822c-1.777,0-3.349,1.138-3.908,2.822 l-4.467,13.506l-13.506,4.467c-1.684,0.559-2.822,2.131-2.822,3.908c0,1.777,1.138,3.349,2.822,3.908l13.506,4.467l4.467,13.506 c0.559,1.684,2.131,2.822,3.908,2.822c1.777,0,3.349-1.138,3.908-2.822l4.467-13.506l13.506-4.467 c1.684-0.559,2.822-2.131,2.822-3.908C123.519,199.971,122.381,198.399,120.697,197.84z M102.639,202.958 c-1.234,0.41-2.204,1.379-2.614,2.614l-1.21,3.655l-1.21-3.655c-0.41-1.234-1.379-2.204-2.614-2.614l-3.655-1.21l3.655-1.21 c1.234-0.41,2.204-1.379,2.614-2.614l1.21-3.655l1.21,3.655c0.41,1.234,1.379,2.204,2.614,2.614l3.655,1.21L102.639,202.958z">
                    </path>
                    <path
                        d="M211.277,53.734l-19.69-6.518l-6.518-19.69c-0.559-1.685-2.132-2.823-3.909-2.823c-1.777,0-3.349,1.138-3.908,2.822 l-6.518,19.69l-19.69,6.518c-1.685,0.559-2.823,2.132-2.823,3.909c0,1.777,1.138,3.349,2.822,3.908l19.69,6.518l6.518,19.69 c0.559,1.685,2.132,2.823,3.909,2.823c1.777,0,3.349-1.138,3.908-2.822l6.518-19.69l19.69-6.518 c1.685-0.559,2.823-2.132,2.823-3.909C214.099,55.865,212.962,54.293,211.277,53.734z M187.036,60.903 c-1.234,0.41-2.204,1.379-2.614,2.614l-3.261,9.847l-3.261-9.847c-0.41-1.234-1.379-2.204-2.614-2.614l-9.847-3.261l9.847-3.261 c1.234-0.41,2.204-1.379,2.614-2.614l3.261-9.847l3.261,9.847c0.41,1.234,1.379,2.204,2.614,2.614l9.847,3.261L187.036,60.903z">
                    </path>
                    <path
                        d="M155.633,117.552l-24.286-8.037l-8.037-24.286c-0.559-1.685-2.132-2.823-3.909-2.823s-3.349,1.138-3.908,2.822 l-8.037,24.286l-24.286,8.037c-1.685,0.559-2.822,2.131-2.822,3.908c0,1.777,1.138,3.349,2.822,3.908l24.286,8.037l8.037,24.286 c0.559,1.685,2.131,2.822,3.908,2.822s3.349-1.138,3.908-2.822l8.037-24.286l24.286-8.037c1.685-0.559,2.822-2.131,2.822-3.908 C158.454,119.682,157.318,118.111,155.633,117.552z M126.796,126.241c-1.234,0.41-2.204,1.379-2.614,2.614l-4.781,14.443 l-4.781-14.443c-0.41-1.234-1.379-2.204-2.614-2.614l-14.443-4.781l14.443-4.781c1.234-0.41,2.204-1.379,2.614-2.614 l4.781-14.443l4.781,14.443c0.41,1.234,1.379,2.204,2.614,2.614l14.443,4.781L126.796,126.241z">
                    </path>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: '#4CAF50' // Default green color
        },
        loading: {
            type: Boolean,
            default: false
        }
    }
}
</script>