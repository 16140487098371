<template>
    <div class="options-menu-modal">
        <div class="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4">
            <h3 class="text-lg font-semibold mb-2">Options</h3>
            <ul>
                <li @click="editConversation"
                    class="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 p-2 rounded">
                    Rename
                </li>
                <li @click="deleteConversation"
                    class="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 p-2 rounded text-red-500">
                    Delete
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OptionsMenuModal',
    props: ['conversation'],
    methods: {
        editConversation() {
            this.$emit('edit', this.conversation);
            this.$modal.hide('options-menu');
        },
        deleteConversation() {
            this.$emit('delete', this.conversation.id);
            this.$modal.hide('options-menu');
        }
    }
}
</script>