<template>
  <div :class="['min-h-screen font-sans', isDarkMode ? 'bg-black text-white' : 'bg-white text-black']">
    <!-- Top Bar -->
    <header class="fixed top-0 left-0 right-0 z-50 p-4 flex justify-between items-center"
      :class="isDarkMode ? 'bg-black' : 'bg-white'">
      <div class="flex items-center">
        <button @click="toggleSidebar" class="mr-4 text-green-500 hover:text-green-600 transition-colors duration-300">
          <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </button>
        <div class="flex items-center">
          <h1 class="text-3xl font-bold text-green-500" id="Logo">Aarya</h1>
          <span class="ml-2 px-2 py-1 text-xs font-semibold rounded-full bg-green-100 text-green-800">v1.1</span>
        </div>
      </div>



      <div class="flex items-center">
        <button @click="toggleDarkMode" class="mr-4">
          <svg v-if="isDarkMode" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z">
            </path>
          </svg>
          <svg v-else class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"></path>
          </svg>
        </button>

        <button v-if="!isLoggedIn" @click="openAuthModal('signup')" class="custom-button">
          Sign Up
        </button>
        <button v-else @click="logout" class="custom-button bg-red-600 hover:bg-red-500">
          Log Out
        </button>
      </div>
    </header>

    <!-- Sidebar Hint Tooltip -->
    <transition name="fade">
      <div v-if="showSidebarHint"
        class="fixed left-16 top-20 bg-green-500 text-white px-4 py-2 rounded-lg shadow-lg z-50">
        Click here or hover to open sidebar
        <button @click="closeSidebarHint" class="ml-2 text-white hover:text-gray-200">
          <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>
    </transition>

    <!-- Hover Zone -->
    <div class="fixed top-0 left-0 h-full z-50" @mouseenter="handleMouseEnter" style="width: 20px;"></div>

    <!-- Sidebar -->
    <aside :class="[
      'fixed top-0 left-0 h-full w-64 p-4 transform transition-transform duration-300 ease-in-out z-40',
      {
        'translate-x-0': isSidebarOpen || (isPinned && !isMobile),
        '-translate-x-full': !isSidebarOpen && (!isPinned || isMobile)
      },
      isDarkMode ? 'bg-black border-gray-800' : 'bg-white border-gray-200',
      isPinned && !isMobile ? 'sidebar-pinned' : ''
    ]" style="margin-top: 64px;" @mouseleave="handleMouseLeave">
      <!-- Sidebar content -->
      <div class="flex flex-col h-full">
        <!-- Fixed Start New Chat Button, Search Toggle, and Pin -->
        <div class="sticky top-0 z-50 bg-inherit">
          <div class="flex items-center justify-between mb-4">
            <button @click="startChat" class="custom-button flex-grow mr-2">
              Start New Chat
            </button>
            <button @click="toggleSearch" :class="[
              'p-2 rounded-lg transition-colors duration-300 ease-in-out',
              isDarkMode ? 'text-gray-400 hover:text-white' : 'text-gray-600 hover:text-black'
            ]">
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </button>
            <button @click="togglePinSidebar" :class="[
              'p-2 rounded-lg transition-colors duration-300 ease-in-out',
              isPinned ? 'text-green-500 hover:text-green-600' : (isDarkMode ? 'text-gray-400 hover:text-white' : 'text-gray-600 hover:text-black')
            ]">
              <svg v-if="isPinned" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                class="w-5 h-5">
                <path
                  d="M12.1715 11L7.51457 6.34311L8.92878 4.92889L15.9999 12L8.92878 19.071L7.51457 17.6568L12.1714 13L2.9996 13.0001L2.99957 11.0001L12.1715 11ZM17.9996 18.9999L17.9996 4.99991H19.9996L19.9996 18.9999H17.9996Z">
                </path>
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5">
                <path
                  d="M12.1715 11L7.51457 6.34311L8.92878 4.92889L15.9999 12L8.92878 19.071L7.51457 17.6568L12.1714 13L2.9996 13.0001L2.99957 11.0001L12.1715 11ZM17.9996 18.9999L17.9996 4.99991H19.9996L19.9996 18.9999H17.9996Z">
                </path>
              </svg>
            </button>
          </div>
          <div class="flex space-x-2 mb-4">
            <button @click="handleAgentsClick" class="custom-button flex-grow relative">
              Agents
              <span v-if="!isPlanPro" class="absolute right-2 top-1/2 transform -translate-y-1/2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
              </span>
            </button>
          </div>
          <div v-if="agents && agents.length > 5" class="flex space-x-2 mb-4">
            <button @click="toggleAgentList" class="custom-button flex-grow">
              {{ showAgentList ? 'Hide Agents' : 'Show Agents' }}
            </button>
          </div>
        </div>

        <!-- Search Input (Toggleable) -->
        <transition name="fade">
          <div v-if="isSearchVisible" class="relative mb-4">
            <input type="text" placeholder="Search conversations..." v-model="searchQuery" @blur="hideSearchOnEmpty"
              :class="[
                'w-full p-2 pl-10 pr-4 rounded-lg transition-all duration-300 ease-in-out',
                isDarkMode
                  ? 'bg-gray-800 text-white placeholder-gray-400 focus:bg-gray-700'
                  : 'bg-gray-100 text-black placeholder-gray-500 focus:bg-white',
                'border-2',
                isDarkMode ? 'border-gray-700 focus:border-green-500' : 'border-gray-200 focus:border-green-500'
              ]" />
            <svg
              class="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 transition-colors duration-300 ease-in-out"
              :class="isDarkMode ? 'text-gray-400' : 'text-gray-500'" fill="none" stroke="currentColor"
              viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </div>
        </transition>

        <!-- Agent List (only shown for Pro users) -->
        <div v-if="showAgentList && agents && agents.length > 0 && isPlanPro" class="mb-4 overflow-y-auto max-h-40">
          <ul class="space-y-2">
            <li v-for="agent in visibleAgents" :key="agent._id" @click="startNewChat(agent._id)"
              class="cursor-pointer hover:bg-green-100 dark:hover:bg-green-900 p-2 rounded transition-colors flex items-center">
              <img :src="agent.image_url" :alt="agent.name" class="w-8 h-8 rounded-full mr-2">
              {{ agent.name }}
            </li>
          </ul>
          <button v-if="agents && agents.length > visibleAgentsCount" @click="showMoreAgents"
            class="text-green-500 hover:underline mt-2">
            Show More
          </button>
        </div>

        <!-- Divider -->
        <hr v-if="showAgentList" class="my-4 border-t border-gray-300 dark:border-gray-700">

        <!-- Conversations List -->
        <div class="flex-grow overflow-y-auto mt-4">
          <ul class="space-y-2">
            <li v-for="conversation in filteredConversations" :key="conversation.id" class="relative group">
              <div class="flex items-center">
                <a @click="selectConversation(conversation.id)" :class="['flex-grow block py-2 px-4 hover:bg-green-600 hover:text-white rounded transition-colors cursor-pointer',
                  currentConversationId === conversation.id ? 'bg-green-600 text-white' : '']">
                  {{ conversation.title || 'Untitled Conversation' }}
                </a>
                <div class="absolute right-2 top-1/2 transform -translate-y-1/2 hidden group-hover:block">
                  <button @click.stop="toggleOptionsMenu(conversation)" class="p-2 text-gray-500 hover:text-gray-700">
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z">
                      </path>
                    </svg>
                  </button>
                </div>
              </div>
              <div v-if="conversation.showOptions"
                class="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                <div class="py-1">
                  <a @click="editConversationTitle(conversation)"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Rename</a>
                  <a @click="handleDeleteClick(conversation.id)"
                    class="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100">Delete</a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- Sidebar Footer (Fixed) -->
      <div :class="['sticky bottom-0 p-2 z-50 mt-auto', isDarkMode ? 'bg-black' : 'bg-inherit']">
        <div :class="['border-t pt-4', isDarkMode ? 'border-gray-700' : 'border-gray-300']">
          <a v-for="link in footerLinks" :key="link.text" @click="handleFooterLink(link)"
            :class="['block py-2 hover:underline cursor-pointer', isDarkMode ? 'text-green-400' : 'text-green-500']">
            {{ link.text }}
          </a>
        </div>
      </div>
    </aside>

    <!-- Main Content -->
    <main
      :class="['main-content pt-16 overflow-y-auto transition-all duration-300 ease-in-out', mainContentClass, (isSidebarOpen || (isPinned && !isMobile)) ? 'ml-64' : 'ml-0']">
      <div v-if="!currentConversationId" v-html="landingPageContent"></div>

      <!-- Chat Interface -->
      <div v-if="currentConversationId" class="max-w-3xl mx-auto h-full overflow-y-auto pb-24 px-4">
        <!-- Conversation History -->
        <div v-for="(message, index) in currentConversation" :key="index" class="mb-6">
          <div
            :class="['p-4 rounded-lg shadow-md', message.role === 'user' ? 'bg-green-600 text-white' : (isDarkMode ? 'bg-white text-black' : 'bg-gray-100 text-black')]">

            <!-- User Message -->
            <div v-if="message.role === 'user'" class="whitespace-pre-wrap">{{ message.content }}</div>

            <div v-else>
              <!-- Check if the message contains a generated image -->
              <div v-if="message.content.startsWith('Generated image:')" class="image-container">
                <img :src="extractImageUrl(message.content)" alt="Generated image" class="generated-image"
                  @load="onImageLoad" @error="onImageError(extractImageUrl(message.content))">
                <div class="download-button-container">
                  <button @click="downloadImage(extractImageUrl(message.content))" class="download-button">
                    Download
                    <svg xmlns="http://www.w3.org/2000/svg" class="download-icon" viewBox="0 0 20 20"
                      fill="currentColor">
                      <path fill-rule="evenodd"
                        d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                    </svg>
                  </button>
                </div>
              </div>
              <!-- Render other messages (text or code) if it's not a generated image -->
              <div v-else>
                <div v-for="(block, blockIndex) in parseMessageContent(message.content)" :key="blockIndex">
                  <!-- Code Block -->
                  <div v-if="block.type === 'code'" class="code-block-container">
                    <div class="code-block-header">
                      <h3>Code Block</h3>
                      <button @click="copyToClipboard(block.content, blockIndex)" class="copy-button">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z" />
                          <path
                            d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z" />
                        </svg>
                        <span v-if="copiedStates[blockIndex]">Copied!</span>
                        <span v-else>Copy</span>
                      </button>
                    </div>
                    <div class="code-block" v-html="renderCodeBlock(block.content, block.language)"></div>
                  </div>

                  <!-- Render markdown content for normal text -->
                  <div v-else class="prose max-w-none" :class="isDarkMode ? 'prose-invert' : ''"
                    v-html="renderMarkdown(block.content)"></div>
                </div>
              </div>

              <!-- Display sources if available -->
              <div v-if="message.sources && message.sources.length > 0" class="mt-4 text-sm"
                :class="isDarkMode ? 'text-gray-400' : 'text-gray-600'">
                <p class="font-bold">Sources:</p>
                <ul class="list-disc pl-5">
                  <li v-for="source in message.sources" :key="source">
                    <a :href="source" target="_blank" class="text-blue-500 hover:underline">{{ source }}</a>
                  </li>
                </ul>
              </div>

              <div class="mt-2 text-xs" :class="isDarkMode ? 'text-gray-400' : 'text-gray-500'">
                Attempt: {{ message.attempt }}
              </div>
            </div>
          </div>

          <!-- Feedback buttons for assistant messages -->
          <div v-if="message.role === 'assistant'" class="mt-2 flex space-x-2">
            <button @click="handleFeedback(index, 'thumbs_up')" class="p-1 text-green-500 hover:text-green-600">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
              </svg>
            </button>
            <button @click="handleFeedback(index, 'thumbs_down')" class="p-1 text-red-500 hover:text-red-600">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  d="M18 9.5a1.5 1.5 0 11-3 0v-6a1.5 1.5 0 013 0v6zM14 9.667v-5.43a2 2 0 00-1.105-1.79l-.05-.025A4 4 0 0011.055 2H5.64a2 2 0 00-1.962 1.608l-1.2 6A2 2 0 004.44 12H8v4a2 2 0 002 2 1 1 0 001-1v-.667a4 4 0 01.8-2.4l1.4-1.866a4 4 0 00.8-2.4z" />
              </svg>
            </button>
            <button @click="retryQuery(index)" class="p-1 text-blue-500 hover:text-blue-600">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                  d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                  clip-rule="evenodd" />
              </svg>
            </button>
            <button @click="copyToClipboard(message.content)" class="p-1 text-gray-500 hover:text-gray-600">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z" />
                <path
                  d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z" />
              </svg>
            </button>
          </div>
        </div>

        <div v-if="isStreaming" class="mb-6">
          <div :class="['p-4 rounded-lg shadow-md', isDarkMode ? 'bg-white text-black' : 'bg-gray-100 text-black']">
            <div class="prose max-w-none" :class="isDarkMode ? 'prose-invert' : ''" v-html="formattedStreamingResponse">
            </div>
          </div>
        </div>

        <!-- Loading indicator -->
        <div v-if="isLoading" class="text-center py-4">
          <div class="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-green-500"></div>
        </div>
      </div>
    </main>

    <!-- PDF Sidebar -->
    <PdfSidebar :isPdfSidebarOpen="isPdfSidebarOpen" :linkedPdfs="linkedPdfs"
      :currentConversationId="currentConversationId" :isdarkmode="isDarkMode" @showError="showError"
      @pdfUploaded="fetchLinkedPdfs" @pdfDeleted="handlePdfDeleted" @close-sidebar="closePdfSidebar" />

    <!-- PDF Sidebar Toggle Button -->
    <button @click="openPdfSidebar"
      class="fixed right-4 bottom-20 bg-green-500 text-white p-2 rounded-full shadow-lg flex items-center"
      v-if="currentConversationId">
      <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z">
        </path>
      </svg>
      <span class="ml-2">PDFs</span>
    </button>

    <!-- Floating Chat Input at the Bottom -->
    <div :class="[
      'fixed bottom-4 left-1/2 transform -translate-x-1/2 p-2 flex flex-col items-center z-30',
      isDarkMode ? 'bg-black' : 'bg-white',
      'rounded-lg shadow-lg w-4/5 md:w-2/3 lg:w-1/2'
    ]">
      <div class="w-full text-sm text-green-500 mb-2">
        <template v-if="isAgentConversation">
          Chatting with Agent: {{ currentAgentName }}
        </template>
        <template v-else>
          <span>Chatting with: </span>
          <template v-if="canChangeModel">
            <select v-model="selectedModel" class="bg-transparent border-none text-green-500 cursor-pointer">
              <option v-for="model in availableModels" :key="model" :value="model">
                {{ model }}
              </option>
            </select>
          </template>
          <template v-else>
            model-r1-mini
            <span v-if="isPlanFree" class="text-xs text-gray-500">(Upgrade to choose models)</span>
          </template>
        </template>
      </div>
      <div class="w-full flex items-start flex-shrink-0">
        <textarea v-model="query" @keydown="handleKeyDown" @input="adjustTextareaHeight" :class="[
          'bg-transparent flex-grow px-4 py-2 focus:outline-none rounded-lg border resize-none',
          isDarkMode ? 'border-gray-600 text-white' : 'border-gray-300 text-black'
        ]" :disabled="isLoading" rows="1" style="min-height: 40px; max-height: 200px; overflow-y: auto;"
          placeholder="Type your message here..."></textarea>
        <button @click="handleQuery" :disabled="!query.trim() || isLoading" :class="[
          'custom-button ml-2',
          {
            'opacity-50 cursor-not-allowed': !query.trim() || isLoading,
            'bg-green-600 hover:bg-green-500': !isLoading && query.trim(),
            'bg-gray-400': isLoading || !query.trim()
          }
        ]">
          {{ isLoading ? 'Sending...' : 'Send' }}
        </button>
      </div>
      <p class="text-xs mt-2 text-gray-500">
        <span v-if="isTouchDevice">Press Enter for a new line. Tap Send to submit.</span>
        <span v-else>Press Enter to send. Use Shift+Enter for a new line.</span>
        Note: Information provided may not always be accurate.
      </p>
    </div>

    <!-- Auth Modal -->
    <transition name="fade">
      <div v-if="showAuthModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div :class="['p-8 rounded-lg max-w-md w-full relative', isDarkMode ? 'bg-black' : 'bg-white']">
          <button @click="closeAuthModal" class="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <h2 class="text-2xl font-bold mb-4 text-green-500">{{ authMode === 'login' ? 'Log In' : 'Sign Up' }}</h2>

          <!-- Validation Error Messages -->
          <div v-if="errorMessage" class="mb-4 text-red-500">
            {{ errorMessage }}
          </div>

          <!-- Sign Up Form -->
          <template v-if="authMode === 'signup'">
            <input v-model="authEmail" type="email" placeholder="Email"
              :class="['w-full p-2 mb-4 rounded', isDarkMode ? 'bg-black text-white' : 'bg-white text-black']">
            <input v-model="authPassword" type="password" placeholder="Password (min 8 characters)"
              :class="['w-full p-2 mb-4 rounded', isDarkMode ? 'bg-black text-white' : 'bg-white text-black']">
          </template>

          <!-- Log In Form -->
          <template v-else-if="authMode === 'login'">
            <input v-model="authEmail" type="email" placeholder="Email"
              :class="['w-full p-2 mb-4 rounded', isDarkMode ? 'bg-black text-white' : 'bg-white text-black']">
            <input v-model="authPassword" type="password" placeholder="Password"
              :class="['w-full p-2 mb-4 rounded', isDarkMode ? 'bg-black text-white' : 'bg-white text-black']">
            <a @click="forgotPassword" class="text-green-500 hover:underline cursor-pointer block mb-4">Forgot
              Password?</a>
          </template>

          <!-- Loading Indicator -->
          <div v-if="isAuthLoading" class="flex justify-center mb-4">
            <div class="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-green-500"></div>
          </div>

          <!-- Auth Button -->
          <button @click="handleAuth" class="custom-button w-full" :disabled="isAuthLoading">
            {{ isAuthLoading ? 'Processing...' : (authMode === 'login' ? 'Log In' : 'Sign Up') }}
          </button>

          <!-- Switch Auth Mode -->
          <p class="mt-4 text-center">
            {{ authMode === 'login' ? "Don't have an account?" : "Already have an account?" }}
            <a @click="toggleAuthMode" class="text-green-500 hover:underline cursor-pointer">
              {{ authMode === 'login' ? 'Sign Up' : 'Log In' }}
            </a>
          </p>
          <p class="mt-4 text-center text-sm text-gray-600 dark:text-gray-400">
            If you have created an account in Yugin Nebula, you can use the same username or email and password to log
            in.
          </p>
        </div>
      </div>
    </transition>

    <!-- Edit Conversation Title Modal -->
    <transition name="fade">
      <div v-if="showEditTitleModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div :class="['p-8 rounded-lg max-w-md w-full relative', isDarkMode ? 'bg-gray-800' : 'bg-white']">
          <h2 class="text-2xl font-bold mb-4 text-green-500">Edit Conversation Title</h2>
          <input v-model="editingTitle" type="text"
            :class="['w-full p-2 mb-4 rounded', isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-200 text-black']">
          <button @click="saveConversationTitle" class="custom-button w-full">Save</button>
          <button @click="closeEditTitleModal"
            class="mt-2 w-full py-2 px-4 bg-gray-300 text-black rounded hover:bg-gray-400">Cancel</button>
        </div>
      </div>
    </transition>

    <!-- Error Notification Component -->
    <transition name="fade">
      <div v-if="errorMessage" class="fixed top-20 right-4 bg-red-500 text-white px-4 py-2 rounded shadow-lg z-50">
        {{ errorMessage }}
      </div>
    </transition>

    <!-- Upgrade to Pro Dialog -->
    <transition name="fade">
      <div v-if="showUpgradeDialog" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div :class="['p-8 rounded-lg max-w-md w-full relative', isDarkMode ? 'bg-gray-800' : 'bg-white']">
          <h2 class="text-2xl font-bold mb-4 text-green-500">Upgrade to Pro</h2>
          <p class="mb-4">Unlock the Agents feature and more by upgrading to our Pro plan!</p>
          <button @click="goToUpgradePage" class="custom-button w-full mb-2">Upgrade Now</button>
          <button @click="closeUpgradeDialog" class="w-full py-2 px-4 bg-gray-300 text-black rounded hover:bg-gray-400">
            Maybe Later
          </button>
        </div>
      </div>
    </transition>
  </div>

  <ConfirmationDialog :is-open="isDeleteDialogOpen" title="Delete Conversation"
    description="Are you sure you want to delete this conversation? This action cannot be undone."
    :is-dark-mode="isDarkMode" @confirm="confirmDelete" @cancel="cancelDelete" />

  <WelcomeDialog v-if="showWelcomeDialog" :isDarkMode="isDarkMode" @close="handleCloseWelcomeDialog" />

  <!-- What's New Dialog -->
  <WhatsNewDialog v-if="showWhatsNewDialog" :isDarkMode="isDarkMode" @close="closeWhatsNewDialog" />

  <options-menu-modal v-if="selectedConversation" name="options-menu" :conversation="selectedConversation"
    @edit="editConversationTitle" @delete="handleDeleteClick"></options-menu-modal>

</template>
<script>
import { ref, onMounted, watch, computed, nextTick, onUpdated, onUnmounted } from 'vue';
import axios from '../axios';
import { useRouter } from 'vue-router';
import { getValidAuthToken } from '@/auth';
import ConfirmationDialog from '@/components/ConfirmationDialogComponent.vue';
import MarkdownIt from 'markdown-it';
import hljs from 'highlight.js';
import WelcomeDialog from '@/components/WelcomeDialog.vue';
import PdfSidebar from '@/components/PdfSidebar.vue';
import WhatsNewDialog from '@/components/WhatsNewDialog.vue';
import OptionsMenuModal from '@/components/OptionsMenuModal.vue'


export default {
  name: 'YuginAI',
  components: {
    ConfirmationDialog,
    WelcomeDialog,
    PdfSidebar,
    WhatsNewDialog,
    OptionsMenuModal
  },
  setup() {
    const md = new MarkdownIt({
      highlight: function (str, lang) {
        if (lang && hljs.getLanguage(lang)) {
          try {
            return `<pre class="hljs"><code>${hljs.highlight(str, { language: lang }).value}</code></pre>`;
          } catch (__) {
            // Error intentionally ignored
          }
        }
        return `<pre class="hljs"><code>${md.utils.escapeHtml(str)}</code></pre>`;
      }
    });


    const router = useRouter();
    const isLoggedIn = ref(false);
    const isSidebarOpen = ref(false);
    const isDarkMode = ref(false);
    const isTyping = ref(false);
    const isTouchDevice = ref(false);


    const isPinned = ref(false);

    const mainContentClass = computed(() => {
      return {
        'ml-0': !isSidebarOpen.value,
        'ml-64': isSidebarOpen.value,
      };
    });
    const query = ref('');
    const suggestions = ref([]);
    const showAuthModal = ref(false);
    const authMode = ref('login');
    const authEmail = ref('');
    const authPassword = ref('');
    const conversations = ref([]);
    const currentConversationId = ref(null);
    const isAgentConversation = ref(false);
    const currentConversation = ref([]);
    const isLoading = ref(false);
    const showEditTitleModal = ref(false);
    const editingConversation = ref(null);
    const editingTitle = ref('');
    const isDeleteDialogOpen = ref(false);
    const conversationToDelete = ref(null);
    const searchQuery = ref('');
    const isSearchVisible = ref(false);
    const isAuthLoading = ref(false);
    const errorMessage = ref('');
    const showWelcomeDialog = ref(false);
    const isMobile = ref(false);
    const linkedPdfs = ref([]);
    const isStreaming = ref(false);
    const streamingResponse = ref('');
    // const eventSource = ref(null);
    const isSearchingWeb = ref(false);
    const showSidebarHint = ref(true);
    const selectedFiles = ref([]);
    const uploadedFiles = ref([]);
    const fileInput = ref(null);
    const isPdfSidebarOpen = ref(false);
    const imageLoadError = ref(null);
    const formattedStreamingResponse = computed(() => renderMarkdown(streamingResponse.value));
    const isGeneratingImage = ref(false);
    const isEventLoading = ref(false);
    const selectedConversation = ref(null);

    // Inside your setup function
    const copiedStates = ref({});
    const visibleAgentsCount = ref(5);
    const agents = ref([]);
    const showAgentList = ref(false);
    const userSubscription = ref(null);
    const showUpgradeDialog = ref(false);
    const currentAgentName = ref('');
    const selectedModel = ref('model-r1-mini')

    const planTypes = {
      FREE: 'Free plan',
      BASIC: 'Basic plan (with Aarya ai)',
      PREMIUM: 'premium plan (with Aarya AI)',
      PRO: 'pro plan (with aarya AI)'
    };

    const isPlanPro = computed(() =>
      userSubscription.value?.plan_name === planTypes.PRO ||
      userSubscription.value?.plan_name === planTypes.PREMIUM
    );

    const isPlanBasic = computed(() =>
      userSubscription.value?.plan_name === planTypes.BASIC
    );

    const isPlanFree = computed(() =>
      userSubscription.value?.plan_name === planTypes.FREE
    );

    const canUseAgents = computed(() => isPlanPro.value);

    const canChangeModel = computed(() =>
      isPlanPro.value || isPlanBasic.value
    );

    const availableModels = computed(() => {
      if (isPlanPro.value) {
        return ['model-r1-mini', 'model-r1'];
      } else if (isPlanBasic.value) {
        return ['model-r1-mini', 'model-r1'];
      } else {
        return ['model-r1-mini'];
      }
    });


    const fetchUserSubscription = async () => {
      try {
        const response = await axios.get('/user_subscription', {
          headers: { requiresAuth: true },
        });
        userSubscription.value = response.data;
      } catch (error) {
        console.error('Error fetching user subscription:', error);
        showError('Failed to fetch user subscription. Please try again.');
      }
    };

    const toggleOptionsMenu = async (conversation) => {
      conversations.value.forEach(conv => {
        conv.showOptions = false;
      });
      conversation.showOptions = !conversation.showOptions;
      selectedConversation.value = conversation.showOptions ? conversation : null;
    };



    const goToUpgradePage = () => {
      // Replace with your actual upgrade page URL
      window.open('https://yugin.co/pricing', '_blank');
      closeUpgradeDialog();
    };

    const closeUpgradeDialog = () => {
      showUpgradeDialog.value = false;
    };

    const handleAgentsClick = () => {
      if (canUseAgents.value) {
        router.push('/agents');
      } else {
        showUpgradeDialog.value = true;
      }
    };

    const goToAgentsPage = () => {
      if (isPlanPro.value) {
        router.push('/agents');
      } else {
        showError('Agents feature is only available for Pro users. Please upgrade your plan.');
      }
    };

    const renderCodeBlock = (codeContent, language) => {
      const fencedCode = `\`\`\`${language}\n${codeContent}\n\`\`\``;
      return md.render(fencedCode);
    };


    const renderMarkdown = (content) => {
      return md.render(content);
    };
    let streamBuffer = '';
    const checkMobile = () => {
      isMobile.value = window.innerWidth < 768; // Adjust this breakpoint as needed
    };

    onMounted(() => {
      checkMobile();
      window.addEventListener('resize', checkMobile);
    });

    const adjustTextareaHeight = (event) => {
      const textarea = event.target;
      textarea.style.height = 'auto';

      textarea.style.height = `${textarea.scrollHeight}px`;

    };

    const showWhatsNewDialog = ref(false);

    const getLanguageFromCode = async (code) => {
      const firstLine = code.split('\n')[0];
      const languageParts = firstLine.split('\\');
      return languageParts[0] || 'Code';
    };
    const handleFooterLink = (link) => {
      if (link.text === "What's New") {
        showWhatsNewDialog.value = true;
      } else if (link.url) {
        window.open(link.url, '_blank');
      }
    }

    const closeWhatsNewDialog = () => {
      showWhatsNewDialog.value = false;
    };


    const landingPageContent = computed(() => {
      return `
    <div class="landing-page max-w-4xl mx-auto px-4 py-8" :class="isDarkMode ? 'text-white' : 'text-black'">
      <h1 class="text-4xl font-bold text-green-600 mb-6">Welcome to Aarya</h1>
      
      <p class="text-xl mb-8">Your intelligent assistant for knowledge and productivity.</p>
      
      <div class="grid md:grid-cols-2 gap-8 mb-12">
        <div class="feature-card p-6 rounded-lg shadow-md" :class="isDarkMode ? 'bg-gray-800' : 'bg-white'">
          <h2 class="text-2xl font-semibold mb-4 text-green-600">Ask Anything</h2>
          <p>Get instant answers to your questions on a wide range of topics.</p>
        </div>
        <div class="feature-card p-6 rounded-lg shadow-md" :class="isDarkMode ? 'bg-gray-800' : 'bg-white'">
          <h2 class="text-2xl font-semibold mb-4 text-green-600">Code Assistance</h2>
          <p>Get help with coding problems, debugging, and best practices.</p>
        </div>
        <div class="feature-card p-6 rounded-lg shadow-md" :class="isDarkMode ? 'bg-gray-800' : 'bg-white'">
          <h2 class="text-2xl font-semibold mb-4 text-green-600">Creative Writing</h2>
          <p>Brainstorm ideas and get assistance with various writing tasks.</p>
        </div>
        <div class="feature-card p-6 rounded-lg shadow-md" :class="isDarkMode ? 'bg-gray-800' : 'bg-white'">
          <h2 class="text-2xl font-semibold mb-4 text-green-600">Analysis &amp; Insights</h2>
          <p>Get help analyzing data, trends, and complex information.</p>
        </div>
      </div>
      
      <div class="getting-started p-6 rounded-lg" :class="isDarkMode ? 'bg-gray-800' : 'bg-green-100'">
        <h2 class="text-2xl font-semibold mb-4" :class="isDarkMode ? 'text-green-500' : 'text-green-700'">Getting Started</h2>
        <ol class="list-decimal list-inside space-y-2">
          <li>Type your question or request in the input box at the bottom of the screen.</li>
          <li>Press Enter or click the Send button to start a conversation.</li>
          <li>Aarya will respond with helpful information or ask for clarification if needed.</li>
          <li>Continue the conversation or start a new one at any time!</li>
        </ol>
      </div>
    </div>
  `;
    });

    const filteredConversations = computed(() => {
      if (!searchQuery.value) {
        return conversations.value;
      }
      const filtered = conversations.value.filter(conversation =>
        conversation.title.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
      return filtered;
    });

    const handleCloseWelcomeDialog = () => {
      showWelcomeDialog.value = false;
    };

    const handleMouseEnter = () => {
      if (!isPinned.value && !isMobile.value) {
        isSidebarOpen.value = true;
      }
    };

    const handleMouseLeave = () => {
      if (!isPinned.value && !isMobile.value) {
        isSidebarOpen.value = false;
      }
    };

    const footerLinks = [
      { text: "What's New" },
      { text: 'Pricing', url: 'https://yugin.co.in/pricing' },
      { text: 'About', url: 'https://yugin.co.in/about' },
      { text: 'Privacy', url: 'https://yugin.co.in/privacy' },
      { text: 'Terms', url: 'https://yugin.co.in/terms-and-conditions' },
    ];

    const toggleSearch = () => {
      isSearchVisible.value = !isSearchVisible.value;
      if (isSearchVisible.value) {
        // Focus the input after it becomes visible
        setTimeout(() => {
          document.querySelector('input[type="text"]').focus();
        }, 0);
      }
    };

    const hideSearchOnEmpty = () => {
      if (!searchQuery.value) {
        isSearchVisible.value = false;
      }
    };
    const editConversationTitle = (conversation) => {
      editingConversation.value = conversation;
      editingTitle.value = conversation.title || '';
      showEditTitleModal.value = true;
    };

    const closeEditTitleModal = () => {
      showEditTitleModal.value = false;
      editingConversation.value = null;
      editingTitle.value = '';
    };

    const saveConversationTitle = async () => {
      try {
        await axios.post(
          '/update_conversation_title',
          {
            conversation_id: editingConversation.value.id,
            title: editingTitle.value,
          },
          { headers: { requiresAuth: true } }
        );

        const index = conversations.value.findIndex(
          (c) => c.id === editingConversation.value.id
        );
        if (index !== -1) {
          conversations.value[index].title = editingTitle.value;
        }

        closeEditTitleModal();
      } catch (error) {
        showError('Failed to update conversation title. Please try again.');
      }
    };

    const closeSidebarHint = () => {
      showSidebarHint.value = false;
      localStorage.setItem('sidebarHintShown', 'true');
    };

    onMounted(() => {
      // Check if welcome dialog should be shown
      const welcomeDialogShown = localStorage.getItem('welcomeDialogShown');
      if (!welcomeDialogShown) {
        showWelcomeDialog.value = true;
      }
      const sidebarHintShown = localStorage.getItem('sidebarHintShown');
      if (sidebarHintShown) {
        showSidebarHint.value = false;
      } else {
        setTimeout(() => {
          showSidebarHint.value = false;
        }, 5000); // Hide the hint after 5 seconds
      }
    });

    onMounted(async () => {
      try {
        const token = await getValidAuthToken();
        if (token) {
          isLoggedIn.value = true;
          await fetchConversations();
          await fetchUserSubscription();

          const conversationId = router.currentRoute.value.params.id;
          if (conversationId) {
            await selectConversation(conversationId);
          }
        } else {
          isLoggedIn.value = false;
        }
      } catch (error) {
        isLoggedIn.value = false;
        console.error('Error during initialization:', error);
        if (router.currentRoute.value.name === 'Conversation') {
          router.push('/404');
        }
      }
    });

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        if (isTouchDevice.value) {
          // On touch devices, always add a new line
          event.preventDefault();
          query.value += '\n';
          nextTick(() => {
            adjustTextareaHeight({ target: event.target });
          });
        } else {
          // On non-touch devices, keep the current behavior
          if (!event.shiftKey) {
            event.preventDefault();
            handleQuery();
          }
        }
      }
    };

    const toggleDarkMode = () => {
      isDarkMode.value = !isDarkMode.value;
      localStorage.setItem('isDarkMode', isDarkMode.value);
    };

    onMounted(() => {
      const savedMode = localStorage.getItem('isDarkMode');
      isDarkMode.value = savedMode === 'true';
      document.addEventListener('click', closeAllOptionsMenus);

    });



    const fetchConversations = async () => {
      try {
        const res = await axios.get('/user_conversations', {
          headers: { requiresAuth: true },
        });
        conversations.value = res.data.conversations.map(conv => ({
          ...conv,
          showOptions: false
        }));
      } catch (error) {
        showError('Failed to fetch conversations. Please try again.');
      }
    };

    const closeAllOptionsMenus = () => {
      conversations.value.forEach(conv => {
        conv.showOptions = false;
      });
    };

    onUnmounted(() => {
      document.removeEventListener('click', closeAllOptionsMenus);
    });

    // const containsUrl = (text) => {
    //   const urlPattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
    //   return urlPattern.test(text);
    // };

    const clearStreamingResponse = () => {
      streamingResponse.value = '';
    };

    const handleQuery = async () => {
      if (!query.value.trim() || isLoading.value) return;
      if (!isLoggedIn.value) {
        openAuthModal('login');
        return;
      }
      if (!currentConversationId.value) {
        try {
          await startNewChat();
        } catch (error) {
          console.error('Error starting new chat:', error);
          showError('Failed to start a new chat. Please try again.');
          return;
        }
      }

      clearStreamingResponse();

      try {
        isLoading.value = true;
        isTyping.value = true;
        isStreaming.value = true;

        if (!Array.isArray(currentConversation.value)) {
          currentConversation.value = [];
        }

        currentConversation.value.push({
          role: 'user',
          content: query.value,
        });

        const authToken = await getValidAuthToken();
        if (!authToken) {
          throw new Error('No valid auth token available');
        }
        const chatRequest = {
          conversation_id: currentConversationId.value,
          message: query.value,
        };

        if (!isAgentConversation.value) {
          chatRequest.model = selectedModel.value;
        }

        const endpoint = isAgentConversation.value
          ? `https://aarya.api.yugin.co/agents/${currentConversationId.value}/chat`
          : 'https://aarya.api.yugin.co/chat';
        // ? `http://127.0.0.1:8000/agents/${currentConversationId.value}/chat`
        // : 'http://127.0.0.1:8000/chat';

        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          },
          body: JSON.stringify(chatRequest)
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let done = false;
        while (!done) {
          const { value, done: doneReading } = await reader.read();
          done = doneReading;
          if (value) {
            const chunk = decoder.decode(value, { stream: true });
            handleStreamChunk(chunk);
          }
        }

        await finishStreaming();
        query.value = '';
      } catch (error) {
        console.error('Error in handleQuery:', error);
        isStreaming.value = false;
        isLoading.value = false;
        isTyping.value = false;
        handleError(error);
      }
    };


    const extractImageUrl = (content) => {
      const match = content.match(/Generated image: (https?:\/\/[^\s]+)/);
      return match ? match[1] : null;
    };

    const onImageLoad = () => {
      // You can add any image load handling logic here
    };

    const onImageError = (url) => {
      console.error(`Failed to load image: ${url}`);
      // You can add logic here to handle image loading errors, e.g., displaying a placeholder
    };

    const handleStreamChunk = async (chunk) => {
      const lines = chunk.split('\n');

      for (const line of lines) {
        if (line.trim() === '') continue;

        if (line.startsWith('data:')) {
          const data = line.slice(5).trim();
          if (data === '[DONE]') {
            await finishStreaming();
          } else if (data.startsWith('{')) {
            handleJsonData(data);
          } else {
            streamingResponse.value += data + ' ';
          }
        } else if (line.startsWith('event:')) {
          const eventType = line.slice(6).trim();
          handleEventType(eventType);
        }
      }
    };

    // const parseEventLine = (line) => {
    //   const [type, ...rest] = line.split(': ');
    //   return [type.trim(), rest.join(': ').trim()];
    // };

    // const handleDataEvent = (data) => {
    //   if (data === '[DONE]') {
    //     finishStreaming();
    //   } else if (data.startsWith('{')) {
    //     handleJsonData(data);
    //   } else {
    //     streamingResponse.value += data + ' ';
    //   }
    // };

    const handleJsonData = (data) => {
      try {
        const jsonData = JSON.parse(data);
        if (isAgentConversation.value) {
          if (jsonData.type === 'stream') {
            streamingResponse.value += jsonData.content + ' ';
          } else if (jsonData.type === 'clarification' || jsonData.type === 'final_response') {
            currentConversation.value.push({
              role: 'assistant',
              content: jsonData.content,
              id: jsonData.message_id
            });
          }
        } else {
          if (jsonData.model_used) {
            handleMetadata(jsonData);
          } else if (jsonData.image_url) {
            handleImageGeneration(jsonData.image_url);
          } else if (jsonData.nsfw_warning) {
            handleNsfwWarning(jsonData.nsfw_warning);
          }
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    };

    const handleEventType = (eventType) => {
      if (eventType === 'info') {
        isSearchingWeb.value = true;
      }
      // Handle other event types as needed
    };



    const handleImageGeneration = (imageUrl) => {
      currentConversation.value.push({
        role: 'assistant',
        type: 'image',
        content: 'Generated image:',
        imageUrl: imageUrl
      });
      isGeneratingImage.value = false;
    };

    const handleNsfwWarning = (warning) => {
      currentConversation.value.push({
        role: 'system',
        type: 'nsfw_warning',
        content: warning
      });
    };

    const finishStreaming = async () => {
      isStreaming.value = false;
      isLoading.value = false;
      isTyping.value = false;
      isSearchingWeb.value = false;
      isGeneratingImage.value = false;
      isEventLoading.value = false;

      if (streamingResponse.value.trim()) {
        try {
          const res = await axios.get(
            `/conversation_history?conversation_id=${currentConversationId.value}`,
            { headers: { requiresAuth: true } }
          );
          currentConversation.value = res.data.messages;
          linkedPdfs.value = res.data.linked_pdfs || [];

        } catch (error) {
          console.error('Error fetching updated conversation:', error);
          showError('Failed to update conversation. Please refresh the page.');
        }
      }
      streamingResponse.value = '';
      streamBuffer = '';
    };

    const handleMetadata = (metadata) => {
      if (metadata.sources && metadata.sources.length > 0) {
        // Update the last message in the conversation with the sources
        const lastMessage = currentConversation.value[currentConversation.value.length - 1];
        if (lastMessage && lastMessage.role === 'assistant') {
          lastMessage.sources = metadata.sources;
        }
      }
    };


    const handleError = (error) => {
      if (error.response) {
        switch (error.response.status) {
          case 402:
            showError('Your subscription is inactive. Please renew your subscription to continue using the service.');
            break;
          case 429:
            showError('You have exceeded your token limit for this billing cycle. Please upgrade your plan or wait for the next cycle.');
            break;
          case 500:
            showError(error.response.data.detail || 'An unexpected error occurred. Please try again later.');
            break;
          default:
            showError('An error occurred while processing your request. Please try again.');
        }
      } else if (error.request) {
        showError('Network error. Please check your internet connection and try again.');
      } else {
        showError('An unexpected error occurred. Please try again later.');
      }
    };

    const showError = (message) => {
      errorMessage.value = message;
      setTimeout(() => {
        errorMessage.value = '';
      }, 5000); // Hide error after 5 seconds
    };

    const dismissError = () => {
      errorMessage.value = '';
    };

    const generateChatName = (firstQuery) => {
      const cleanQuery = firstQuery.replace(/[^\w\s]/gi, '').trim();
      const words = cleanQuery.split(' ').slice(0, 5);
      const chatName = words.join(' ') + (words.length === 5 ? '...' : '');
      return chatName || 'New Conversation';
    };

    const updateChatName = async (conversationId, chatName) => {
      try {
        await axios.post(
          '/update_conversation_title',
          {
            conversation_id: conversationId,
            title: chatName,
          },
          { headers: { requiresAuth: true } }
        );

        const index = conversations.value.findIndex(
          (conv) => conv.id === conversationId
        );
        if (index !== -1) {
          conversations.value[index].title = chatName;
        }
      } catch (error) {
        console.error('Error updating chat name:', error);
      }
    };

    const containsCode = (content) => {
      return (
        content.includes('```') ||
        content.match(/^[a-zA-Z_$][a-zA-Z0-9_$]*\s*\([^)]*\)\s*{/)
      );
    };

    const parseMessageContent = (content) => {
      if (typeof content !== 'string') {
        throw new TypeError('Content must be a string');
      }

      const blocks = [];
      const codeBlockRegex = /```(\w+)?\n([\s\S]*?)```/g;
      let lastIndex = 0;
      let match;

      try {
        while ((match = codeBlockRegex.exec(content)) !== null) {
          if (match.index > lastIndex) {
            blocks.push({ type: 'text', content: content.slice(lastIndex, match.index) });
          }
          blocks.push({
            type: 'code',
            language: match[1] || 'plaintext',
            content: match[2].trim()
          });
          lastIndex = match.index + match[0].length;
        }

        if (lastIndex < content.length) {
          blocks.push({ type: 'text', content: content.slice(lastIndex) });
        }
      } catch (error) {
        console.error('Error parsing message content:', error);
        return [{ type: 'text', content: content }];
      }

      return blocks;
    };


    const copyToClipboard = (text, index) => {
      navigator.clipboard.writeText(text).then(() => {
        copiedStates.value[index] = true;
        setTimeout(() => {
          copiedStates.value[index] = false;
        }, 2000); // Reset after 2 seconds
      }).catch((err) => {
        console.error('Could not copy text: ', err);
      });
    };


    const downloadImage = (url) => {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'generated_image.jpeg';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(error => {
          console.error('Error downloading image:', error);
        });
    };

    const selectSuggestion = (suggestion) => {
      query.value = suggestion.text;
      handleQuery();
    };

    const startChat = () => {
      currentConversationId.value = null;
      currentConversation.value = [];
      router.push('/');
    };

    const triggerFileInput = () => {
      fileInput.value.click();
    };

    const handleFileSelect = (event) => {
      const file = event.target.files[0];
      const maxSize = 100 * 1024 * 1024; // 100 MB in bytes

      if (!file) return;

      if (file.type !== 'application/pdf') {
        showError('Only PDF files are allowed.');
        return;
      }

      if (file.size > maxSize) {
        showError('Each file must be less than 100 MB.');
        return;
      }

      selectedFiles.value = [file]; // Only one file can be selected at a time
    };


    const removeSelectedFile = (index) => {
      selectedFiles.value.splice(index, 1);
    };

    const uploadFiles = async () => {
      if (!selectedFiles.value.length) return;

      const formData = new FormData();
      formData.append('file', selectedFiles.value[0]);
      formData.append('conversation_id', currentConversationId.value);

      try {
        const response = await axios.post('/upload_pdf', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            requiresAuth: true
          },
        });
        uploadedFiles.value.push(response.data.s3_key);
        selectedFiles.value = [];
        showError('PDF uploaded and processed successfully', 'success');
      } catch (error) {
        console.error(error);
        handleError(error);
      }
    };


    const deleteFile = async (file) => {
      try {
        await axios.delete(`/delete_file/${currentConversationId.value}/${file.id}`, {
          headers: { requiresAuth: true }
        });
        uploadedFiles.value = uploadedFiles.value.filter(f => f.id !== file.id);
        showError('File deleted successfully', 'success');
      } catch (error) {
        handleError(error);
      }
    };

    const startNewChat = async (agentId = null) => {
      try {
        const payload = {
          conversation_type: agentId ? "agent" : "general",
          agent_id: agentId
        };

        const res = await axios.post(
          '/start_conversation',
          JSON.stringify(payload),
          {
            headers: {
              requiresAuth: true,
              'Content-Type': 'application/json'
            }
          }
        );

        currentConversationId.value = res.data.conversation_id;
        isAgentConversation.value = !!agentId;
        currentAgentName.value = res.data.agent_name;
        const defaultTitle = agentId ? `Chat with Agent ${agentId}` : 'New Conversation';
        conversations.value.unshift({
          id: currentConversationId.value,
          title: defaultTitle,
          agent_id: agentId
        });
        currentConversation.value = [];
        router.push(`/conversation/${currentConversationId.value}`);

        watch(
          () => currentConversation.value.length,
          async (newLength) => {
            if (newLength === 2) {
              const firstQuery = currentConversation.value[0].content;
              const chatName = generateChatName(firstQuery);
              await updateChatName(currentConversationId.value, chatName);
            }
          }
        );
      } catch (error) {
        console.error('Error starting new chat:', error);
        showError('Failed to start a new chat. Please try again.');
      }
    };

    watch([streamingResponse, currentConversation], () => {
      const container = document.querySelector('.max-w-3xl.mx-auto');
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    });

    const scrollToBottom = () => {
      const container = document.querySelector('.max-w-3xl.mx-auto');
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    };

    const selectConversation = async (conversationId) => {
      if (!conversationId) {
        showError('Invalid conversation ID');
        router.push('/404');
        return;
      }

      try {
        const res = await axios.get(
          `/conversation_history?conversation_id=${conversationId}`,
          { headers: { requiresAuth: true } }
        );

        if (res.data && Array.isArray(res.data.messages)) {
          currentConversationId.value = conversationId;
          currentConversation.value = res.data.messages;
          linkedPdfs.value = res.data.linked_pdfs || [];

          const agentInfo = res.data.agent_info || {};
          isAgentConversation.value = !!agentInfo._id;
          currentAgentName.value = agentInfo.name || 'Unknown Agent';

          if (router.currentRoute.value.path !== `/conversation/${conversationId}`) {
            router.push(`/conversation/${conversationId}`);
          }
        } else {
          throw new Error('Invalid response format or conversation not found');
        }

        await nextTick();
        scrollToBottom();
      } catch (error) {
        console.error('Error in selectConversation:', error);
        showError('Conversation not found or failed to load.');
        currentConversation.value = [];
        linkedPdfs.value = [];
        router.push('/404');
      }
    };
    const handlePdfDeleted = (pdfId) => {
      linkedPdfs.value = linkedPdfs.value.filter(pdf => pdf.id !== pdfId);
    };



    const handleDeleteClick = (conversationId) => {
      conversationToDelete.value = conversationId;
      isDeleteDialogOpen.value = true;
    };

    const deleteConversation = async (conversationId) => {
      try {
        await axios.delete(`/delete_conversation/${conversationId}`, {
          headers: { requiresAuth: true },
        });
        conversations.value = conversations.value.filter(
          (conv) => conv.id !== conversationId
        );

        if (currentConversationId.value === conversationId) {
          currentConversationId.value = null;
          currentConversation.value = [];
          router.push('/');
        }
      } catch (error) {
        showError('Failed to delete conversation. Please try again.');
      }
    };

    const confirmDelete = async () => {
      if (conversationToDelete.value) {
        await deleteConversation(conversationToDelete.value);
      }
      isDeleteDialogOpen.value = false;
      conversationToDelete.value = null;
    };

    const cancelDelete = () => {
      isDeleteDialogOpen.value = false;
      conversationToDelete.value = null;
    };

    const fetchLinkedPdfs = async () => {
      try {
        const res = await axios.get(
          `/conversation_history?conversation_id=${currentConversationId.value}`,
          { headers: { requiresAuth: true } }
        );
        linkedPdfs.value = res.data.linked_pdfs || [];
      } catch (error) {
        console.error('Error fetching linked PDFs:', error);
        showError('Failed to fetch linked PDFs. Please try again.');
      }
    };

    const deleteEmptyConversation = async (conversationId) => {
      try {
        await axios.delete(`/delete_conversation/${conversationId}`, {
          headers: { requiresAuth: true },
        });
        conversations.value = conversations.value.filter(
          (conv) => conv.id !== conversationId
        );
      } catch (error) {
        console.error('Error deleting empty conversation:', error);
      }
    };

    watch(
      () => router.currentRoute.value.params.id,
      async (newId, oldId) => {
        if (newId && newId !== oldId) {
          try {
            await selectConversation(newId);
          } catch (error) {
            console.error('Error loading conversation:', error);
            router.push('/404');
          }
        } else if (!newId) {
          currentConversationId.value = null;
          currentConversation.value = [];
        }
      }
    );

    watch([streamingResponse, currentConversation], () => {
      nextTick(scrollToBottom);
    });

    onMounted(scrollToBottom);
    onUpdated(scrollToBottom);

    const toggleSidebar = () => {
      if (!isPinned.value) {
        isSidebarOpen.value = !isSidebarOpen.value;
      }
    };



    const openPdfSidebar = () => {
      isPdfSidebarOpen.value = true;
    };
    const closePdfSidebar = () => {
      isPdfSidebarOpen.value = false;
    };

    const togglePinSidebar = () => {
      isPinned.value = !isPinned.value;
      if (!isMobile.value) {
        isSidebarOpen.value = isPinned.value;
      }
      localStorage.setItem('sidebarPinned', isPinned.value.toString());  // Changed to toString()
    };

    onMounted(() => {
      const savedMode = localStorage.getItem('isDarkMode');
      isDarkMode.value = savedMode === 'true';

      const savedPinState = localStorage.getItem('sidebarPinned');
      isPinned.value = savedPinState === 'true';  // Changed from !== to ===
      isSidebarOpen.value = isPinned.value;  // Sidebar is open if pinned

      isTouchDevice.value = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);

      fetchAgents();

    });

    const openAuthModal = (mode) => {
      authMode.value = mode;
      showAuthModal.value = true;
    };

    const closeAuthModal = () => {
      showAuthModal.value = false;
    };

    const toggleAuthMode = () => {
      authMode.value =
        authMode.value === 'login' ? 'signup' : 'login';
    };

    const clearForm = () => {
      authEmail.value = '';
      authPassword.value = '';
      errorMessage.value = '';
    }

    const validateForm = () => {


      // Password validation
      if (authPassword.value.length < 8) {
        errorMessage.value = 'Password must be at least 8 characters long.';
        return false;
      }


      // Email validation
      if (!authEmail.value) {
        errorMessage.value = 'Email is required.';
        return false;
      }
      // You might want to add a more comprehensive email validation here

      return true;
    };


    const handleAuth = async () => {
      try {
        isAuthLoading.value = true;
        const endpoint = authMode.value === 'login' ? '/login' : '/signup';
        const payload =
          authMode.value === 'login'
            ? { username: authEmail.value, password: authPassword.value }
            : {
              email: authEmail.value,
              password: authPassword.value,
            };

        const res = await axios.post(endpoint, payload, {
          headers: { requiresAuth: false },
        });

        localStorage.setItem('authToken', res.data.access);
        localStorage.setItem('refreshToken', res.data.refresh);
        isLoggedIn.value = true;
        showAuthModal.value = false;
        closeAuthModal();
        clearForm();
        await fetchConversations();
        if (query.value) {
          await handleQuery();
        }
      } catch (error) {
        console.error(error); // Log the error for debugging purposes

        // Enhanced error handling
        if (error.response) {
          // The request was made and the server responded with a status code
          handleErrorResponse(error.response);
        } else if (error.request) {
          // The request was made but no response was received
          showError('No response from the server. Please check your internet connection and try again.');
        } else {
          // Something happened in setting up the request that triggered an Error
          showError(`Unexpected error: ${error.message}. Please try again.`);
        }
      } finally {
        isAuthLoading.value = false;
      }
    };

    const handleErrorResponse = (response) => {
      // Handle specific HTTP status codes
      switch (response.status) {
        case 400:
          handleValidationErrors(response.data);
          break;
        case 401:
          showError('Authentication failed. Please check your credentials.');
          break;

        default:
          showError(`Server error: ${response.statusText}. Please try again later.`);
          break;
      }
    };

    const handleValidationErrors = (data) => {
      if (data) {
        if (typeof data === 'string') {
          showError(data);
        } else if (typeof data === 'object') {
          const errorMessages = [];
          for (const key in data) {
            if (Array.isArray(data[key])) {
              errorMessages.push(`${key}: ${data[key].join(', ')}`);
            } else {
              errorMessages.push(`${key}: ${data[key]}`);
            }
          }
          showError(errorMessages.join('\n'));
        } else {
          showError('Invalid input. Please check your details and try again.');
        }
      } else {
        showError('Invalid input. Please check your details and try again.');
      }
    };

    const handleFeedback = async (index, feedbackType) => {
      const message = currentConversation.value[index];
      try {
        await axios.post('/message_feedback', {
          message_id: message.id,
          feedback_type: feedbackType
        }, { headers: { requiresAuth: true } });
        // Optionally, you can update the UI to show that feedback was recorded
      } catch (error) {
        showError('Failed to submit feedback. Please try again.');
      }
    };

    const retryQuery = async () => {
      const userMessage = currentConversation.value.find(msg => msg.role === 'user' && msg.content);
      if (userMessage) {
        isLoading.value = true;
        isTyping.value = true;
        clearStreamingResponse();
        isStreaming.value = true;

        const authToken = await getValidAuthToken();
        if (!authToken) {
          throw new Error('No valid auth token available');
        }

        try {
          const response = await fetch('https://aarya.api.yugin.co/retry_chat', {
            // const response = await fetch('http://127.0.0.1:8000/retry_chat', {


            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({
              conversation_id: currentConversationId.value,
              message: userMessage.content,
            })
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          let done = false;
          while (!done) {
            const { value, done: doneReading } = await reader.read();
            done = doneReading;
            if (value) {
              const chunk = decoder.decode(value, { stream: true });
              handleStreamChunk(chunk);
            }
          }

          await finishStreaming();
        } catch (error) {
          console.error('Error in retryQuery:', error);
          isStreaming.value = false;
          isLoading.value = false;
          isTyping.value = false;
          handleError(error);
        }
      }
    };

    const visibleAgents = computed(() => {
      return agents.value.slice(0, visibleAgentsCount.value);
    });

    const toggleAgentList = () => {
      showAgentList.value = !showAgentList.value;
      if (showAgentList.value && agents.value.length === 0) {
        fetchAgents();
      }
    };

    const fetchAgents = async () => {
      try {
        const response = await axios.get('/agents', {
          headers: { requiresAuth: true },
        });
        agents.value = response.data.agents;
        showAgentList.value = true;
      } catch (error) {
        console.error('Error fetching agents:', error);
      }
    };

    const showMoreAgents = () => {
      visibleAgentsCount.value += 5;
    };

    const forgotPassword = () => {
      const forgotPasswordUrl = 'https://yugin.co/forgot-password'; // Replace with your actual forgot password URL
      window.open(forgotPasswordUrl, '_blank');
    };


    const logout = () => {
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      isLoggedIn.value = false;
      conversations.value = [];
      currentConversationId.value = null;
      currentConversation.value = [];
      router.push('/');
    };

    return {
      isAuthLoading,
      clearForm,
      validateForm,
      isLoading,
      containsCode,
      isLoggedIn,
      isSidebarOpen,
      isDarkMode,
      query,
      suggestions,
      showAuthModal,
      authMode,
      authEmail,
      authPassword,
      forgotPassword,
      currentConversation,
      currentConversationId,
      handleQuery,
      selectSuggestion,
      startNewChat,
      selectConversation,
      footerLinks,
      conversations,
      toggleSidebar,
      toggleDarkMode,
      openAuthModal,
      closeAuthModal,
      toggleAuthMode,
      handleAuth,
      logout,
      isTyping,
      showEditTitleModal,
      editingTitle,
      editConversationTitle,
      closeEditTitleModal,
      saveConversationTitle,
      startChat,
      isDeleteDialogOpen,
      handleDeleteClick,
      confirmDelete,
      cancelDelete,
      deleteConversation,
      mainContentClass,
      filteredConversations,
      searchQuery,
      isPinned,
      togglePinSidebar,
      toggleSearch,
      isSearchVisible,
      hideSearchOnEmpty,
      deleteEmptyConversation,
      conversationToDelete,
      landingPageContent,
      handleFeedback,
      retryQuery,
      copyToClipboard,
      errorMessage,
      showError,
      handleMouseEnter,
      handleMouseLeave,
      isMobile,
      dismissError,
      showWelcomeDialog,
      handleCloseWelcomeDialog,
      isSearchingWeb,
      // formatResponse,
      isStreaming,
      streamingResponse,
      formattedStreamingResponse,
      clearStreamingResponse,
      renderMarkdown,
      renderCodeBlock,
      streamBuffer,
      adjustTextareaHeight,
      handleKeyDown,
      isTouchDevice,
      showSidebarHint,
      closeSidebarHint,
      selectedFiles,
      uploadedFiles,
      fileInput,
      triggerFileInput,
      handleFileSelect,
      removeSelectedFile,
      uploadFiles,
      deleteFile,
      linkedPdfs,
      isPdfSidebarOpen,
      fetchLinkedPdfs,
      handlePdfDeleted,
      openPdfSidebar,
      closePdfSidebar,
      handleFooterLink,
      closeWhatsNewDialog,
      showWhatsNewDialog,
      extractImageUrl,
      onImageLoad,
      onImageError,
      imageLoadError,
      parseMessageContent,
      downloadImage,
      isGeneratingImage,
      getLanguageFromCode,
      isEventLoading,
      copiedStates,
      visibleAgents,
      toggleAgentList,
      showMoreAgents,
      goToAgentsPage,
      isAgentConversation,
      isPlanPro,
      handleAgentsClick,
      goToUpgradePage,
      closeUpgradeDialog,
      showUpgradeDialog,
      agents,
      showAgentList,
      currentAgentName,
      toggleOptionsMenu,
      selectedConversation,
      selectedModel,
      isPlanBasic,
      isPlanFree,
      canChangeModel,
      availableModels

    };
  },
};
</script>

<style>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  @apply bg-gray-300 dark:bg-gray-700;
}

::-webkit-scrollbar-thumb {
  @apply bg-green-500 rounded-full;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-green-600;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Apply animations */
.fade-in {
  animation: fadeIn 0.3s ease-in-out;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-in {
  animation: slideIn 0.3s ease-in-out;
}

/* Custom styles */
.custom-button {
  @apply bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-500 transition-colors;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sidebar {
    @apply fixed z-50 transition-transform duration-300 ease-in-out;
  }

  .sidebar.open {
    @apply translate-x-0;
  }

  .sidebar.closed {
    @apply -translate-x-full;
  }

  .sidebar-pinned {
    transform: translateX(-100%) !important;
  }

  .main-content {
    margin-left: 0 !important;
  }
}

textarea {
  min-height: 40px;
  max-height: 200px;
}

/* Input focus styles */
input:focus,
button:focus {
  @apply outline-none ring-2 ring-green-500 ring-opacity-50;
}

/* Transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Sidebar styles */

aside {
  @apply fixed top-0 left-0 h-full w-64 p-4 transform transition-transform duration-300 ease-in-out z-40;
  margin-top: 64px;
  /* Adjust this value to match your header height */
}

.sidebar {
  @apply fixed left-0 top-0 h-full w-64 p-4 transform transition-transform duration-300 ease-in-out;
  margin-top: 64px;
  /* Ensure the sidebar starts below the top bar */
  border-right-width: 1px;
  z-index: 40;
  overflow-y: auto;
}

.sidebar .flex-shrink-0 {
  @apply sticky top-0 z-50;
  background-color: inherit;
}

/* Start New Chat button and Toggle Icon should be fixed */
.sidebar .custom-button {
  @apply bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-500 transition-colors;
  @apply w-full;
}

/* When there are enough conversations, the scrollbar should appear */
.sidebar .flex-grow {
  @apply overflow-y-auto;
}

/* Sidebar toggle animation */
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.sidebar-slide-in {
  animation: slideIn 0.3s forwards;
}

.sidebar-slide-out {
  animation: slideOut 0.3s forwards;
}

.sidebar-pinned {
  transform: translateX(0) !important;
}

/* Main content styles */
.main-content {
  @apply pt-20 ml-64 overflow-y-auto;
  height: calc(100vh - 80px);
  margin-top: 64px;
  /* Ensure content starts below the top bar */
}

/* Main content transition */
.main-content {
  @apply transition-all duration-300 ease-in-out;
}

/* Style for code blocks */
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 1em;
  background-color: #f0f0f0;
  border-radius: 4px;
}

code {
  font-family: 'Courier New', Courier, monospace;
}

/* Floating Input styles */
input[type="text"] {
  @apply h-10 text-sm;
}

/* Typing animation */
@keyframes ellipsis {
  0% {
    content: '.';
  }

  33% {
    content: '..';
  }

  66% {
    content: '...';
  }
}

.dots::after {
  content: '.';
  animation: ellipsis 1.5s infinite;
}

.feedback-button {
  @apply p-1 rounded-full transition-colors duration-200;
}

.feedback-button:hover {
  @apply bg-gray-200;
}

.feedback-button svg {
  @apply w-5 h-5;
}

/* Error notification styles */
.error-notification {
  @apply fixed top-20 right-4 bg-red-500 text-white px-4 py-2 rounded shadow-lg z-50;
}

/* Error notification animation */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.custom-button {
  @apply bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-500 transition-colors;
}

/* Chat Interface message styles */
.prose {
  @apply text-black;
}

.dark .prose {
  @apply text-white;
}

.prose h1,
.prose h2,
.prose h3,
.prose h4,
.prose h5,
.prose h6 {
  @apply font-bold my-2;
}

.prose h1 {
  @apply text-2xl;
}

.prose h2 {
  @apply text-xl;
}

.prose h3 {
  @apply text-lg;
}

.prose p {
  @apply my-2;
}

.prose ul,
.prose ol {
  @apply ml-6 my-2;
}

.prose li {
  @apply my-1;
}

.prose pre {
  @apply bg-gray-100 p-4 rounded my-4 overflow-x-auto;
}

.dark .prose pre {
  @apply bg-gray-800 text-white;
}

.prose code {
  @apply font-mono;
}

.pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 1em;
  background-color: #ffffff;
  border-radius: 4px;
}

.code-block pre {
  margin: 0;
  padding: 1rem;
  overflow-x: auto;
}

.hljs {
  background: transparent;
  /* Remove background if needed */
}

code {
  font-family: 'Courier New', Courier, monospace;
}

/* Dark mode adjustments */
.dark .prose pre {
  @apply bg-black;
}

.dark .prose code {
  @apply text-white;
}

/* Chat input field styles */
input[type="text"] {
  @apply h-10 text-sm;
  background-color: white;
  color: black;
}

.dark input[type="text"] {
  background-color: black;
  color: white;
  border-color: white;
}

button:disabled {
  @apply opacity-50 cursor-not-allowed;
}

.archivo-black-regular {
  font-family: "Archivo Black", sans-serif;
  font-weight: 400;
  font-style: semibold;
}


/* Container for code block and heading */
.code-block-container {
  margin-top: 1.5rem;
  border: 1px solid #ffffff;
  border-radius: 8px;
  overflow: hidden;
}

.dark .code-block-container {
  border-color: #555;
}

.code-block-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #4caf50;
  border-bottom: 1px solid #e0e0e0;
}

.dark .code-block-header {
  background-color: #80e27e;
  border-color: #555;
}

.code-block-header h3 {
  margin: 0;
  color: #4caf50;
  font-size: 1rem;
}

.dark .code-block-header h3 {
  color: #80e27e;
}

.copy-button {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  color: #4a4a4a;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  transition: background-color 0.2s;
}

.dark .copy-button {
  color: #ffffff;
}

.copy-button:hover {
  background-color: #e0e0e0;
}

.copy-button svg {
  margin-right: 0.25rem;
}

.copy-button span {
  margin-left: 0.25rem;
}

.dark .copy-button:hover {
  color: #f0f0f0;
}



.code-block {
  margin: 0;
  padding: 1rem;
  background-color: #f9f9f9;
  overflow-x: auto;
}

.dark .code-block {
  background-color: #2d2d2d;
}

.image-container {
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background-color: #f3f4f6;
}

.generated-image {
  max-width: 100%;
  height: auto;
  display: block;
}

.download-button-container {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
}

.download-button {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.download-button:hover {
  background-color: #4caf50;
}

.download-icon {
  height: 1.25rem;
  width: 1.25rem;
  margin-left: 0.5rem;
}
</style>
