<template>
  <div :class="['fixed top-0 right-0 h-full w-64 shadow-lg transform transition-transform duration-300 ease-in-out', 
                isPdfSidebarOpen ? 'translate-x-0' : 'translate-x-full', 
                isdarkmode ? 'bg-black' : 'bg-white']"
       style="margin-top: 64px; z-index: 40;">
    <div class="p-4">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-lg font-semibold" :class="isdarkmode ? 'text-green-400' : 'text-green-500'">Linked PDFs</h3>
        <button @click="closeSidebar" class="text-gray-500 hover:text-gray-700">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>
      
      <!-- PDF Upload Section -->
      <div class="mb-4">
        <input type="file" @change="handleFileSelect" ref="fileInput" class="hidden" accept=".pdf">
        <button @click="selectAndUploadFile" 
                :class="['px-4 py-2 rounded-lg transition-colors w-full mb-2', 
                         isdarkmode ? 'bg-green-400 text-black hover:bg-green-500' : 'bg-green-500 text-white hover:bg-green-600',
                         { 'opacity-50 cursor-not-allowed': isUploading }]"
                :disabled="isUploading">
          {{ isUploading ? 'Uploading...' : 'Select and Upload PDF' }}
        </button>
        
        <!-- Progress Bar -->
        <div v-if="isUploading" class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mt-2">
          <div class="bg-blue-600 h-2.5 rounded-full" :style="{ width: `${uploadProgress}%` }"></div>
        </div>
        
        <!-- Status Message -->
        <p v-if="statusMessage" :class="isdarkmode ? 'text-green-400' : 'text-green-500'" class="mt-2 text-sm">
          {{ statusMessage }}
        </p>
      </div>

      <!-- List of Linked PDFs -->
      <ul v-if="linkedPdfs.length" class="space-y-2">
        <li v-for="pdf in linkedPdfs" :key="pdf.id" :class="['flex items-center justify-between p-2 rounded-lg', isdarkmode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-black']">
          <span class="truncate mr-2">{{ pdf.file_name }}</span>
          <div>
            <button @click="deletePdf(pdf)" class="text-red-500 hover:text-red-700">
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
              </svg>
            </button>
          </div>
        </li>
      </ul>
      <p v-else :class="isdarkmode ? 'text-gray-400' : 'text-gray-500'">No PDFs linked to this conversation.</p>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  props: {
    isPdfSidebarOpen: Boolean,
    linkedPdfs: Array,
    currentConversationId: String,
    isdarkmode: Boolean,
  },
  data() {
    return {
      selectedFile: null,
      isUploading: false,
      uploadProgress: 0,
      statusMessage: '',
      eventSource: null,
      uploadId: null,
      pollingInterval: null
    }
  },
    methods: {
    closeSidebar() {
      this.$emit('close-sidebar');
    },
    handleFileSelect(event) {
      const file = event.target.files[0];
      if (file && file.type === 'application/pdf') {
        this.selectedFile = file;
        this.uploadFile();
      } else {
        this.$emit('showError', 'Please select a valid PDF file.');
      }
    },
    selectAndUploadFile() {
      this.$refs.fileInput.click();
    },
    async uploadFile() {
      if (!this.selectedFile) return;

      const formData = new FormData();
      formData.append('file', this.selectedFile);
      formData.append('conversation_id', this.currentConversationId);

      this.isUploading = true;
      this.uploadProgress = 0;
      this.statusMessage = 'Initiating upload...';

      try {
        // Step 1: Check PDF limits before uploading
        const limitsResponse = await axios.post(
          '/check-pdf-limits', 
          { conversation_id: this.currentConversationId },
          { headers: { requiresAuth: true } }
        );

        if (limitsResponse.data.message === 'PDF limits check passed.') {
          // Step 2: Proceed with PDF upload
          const uploadResponse = await axios.post('/upload_pdf', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              requiresAuth: true
            },
            onUploadProgress: (progressEvent) => {
              this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }
          });

          this.uploadId = uploadResponse.data.upload_id;
          
          // Step 3: Start listening for status updates
          this.listenForStatusUpdates();

        } else {
          this.$emit('showError', 'PDF limits check failed. Please check your plan.');
          this.resetUploadState();
        }
      } catch (error) {
        this.handleUploadError(error);
      }
    },
    listenForStatusUpdates() {
      this.eventSource = new EventSource(`/upload_status_stream/${this.uploadId}`);
      
      this.eventSource.onmessage = (event) => {
        this.handleStatusUpdate(JSON.parse(event.data));
      };

      this.eventSource.onerror = (error) => {
        console.error('EventSource failed:', error);
        this.eventSource.close();
        this.fallbackToPolling();
      };
    },
    fallbackToPolling() {
      this.statusMessage = 'Continuing to process...';
      this.pollingInterval = setInterval(() => this.pollStatus(), 2000);
    },
    async pollStatus() {
      try {
        const response = await axios.get(`/upload_status/${this.uploadId}`);
        this.handleStatusUpdate(response.data);
      } catch (error) {
        console.error('Polling failed:', error);
        this.handleUploadError(error);
      }
    },
    handleStatusUpdate(status) {
      this.uploadProgress = status.progress;
      this.statusMessage = status.message;

      if (status.status === 'completed') {
        this.completeUpload();
      } else if (status.status === 'error') {
        this.handleUploadError(new Error(status.message));
      }
    },
    completeUpload() {
      this.resetUploadState();
      this.$emit('pdfUploaded');
      this.selectedFile = null;
    },
    handleUploadError(error) {
      this.resetUploadState();
      if (error.response && error.response.status === 429) {
        this.$emit('showError', error.response.data.detail);
      } else {
        this.$emit('showError', 'Failed to upload PDF. Please try again.');
      }
      console.error('Upload error:', error);
    },
    resetUploadState() {
      this.isUploading = false;
      this.uploadProgress = 0;
      this.statusMessage = '';
      if (this.eventSource) {
        this.eventSource.close();
      }
      if (this.pollingInterval) {
        clearInterval(this.pollingInterval);
      }
    },
    async deletePdf(pdf) {
      try {
        await axios.delete(`/delete_pdf/${pdf.id}`, {
          headers: { requiresAuth: true }
        });
        this.$emit('pdfDeleted', pdf.id);
      } catch (error) {
        this.$emit('showError', 'Failed to delete PDF. Please try again.');
      }
    }
  },
  beforeUnmount() {
    this.resetUploadState();
  }
}
</script>