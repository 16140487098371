<template>
  <Teleport to="body">
    <div v-if="isOpen" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div 
        :class="[
          'p-6 rounded-lg w-full max-w-md transition-all duration-300 ease-in-out transform',
          isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800',
          isOpen ? 'scale-100 opacity-100' : 'scale-95 opacity-0'
        ]"
      >
        <h2 class="text-2xl font-bold mb-4">{{ title }}</h2>
        <p class="mb-6 text-sm md:text-base">{{ description }}</p>
        <div class="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4">
          <button 
            @click="$emit('cancel')" 
            class="px-4 py-2 rounded text-sm font-medium transition-colors duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2"
            :class="isDarkMode ? 'bg-gray-700 text-white hover:bg-gray-600 focus:ring-gray-500' : 'bg-gray-200 text-gray-800 hover:bg-gray-300 focus:ring-gray-300'"
          >
            Cancel
          </button>
          <button 
            @click="$emit('confirm')" 
            class="px-4 py-2 rounded text-sm font-medium text-white transition-colors duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            :class="isDarkMode ? 'bg-red-600 hover:bg-red-700' : 'bg-red-500 hover:bg-red-600'"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ConfirmationDialog',
  props: {
    isOpen: Boolean,
    title: String,
    description: String,
    isDarkMode: Boolean
  },
  emits: ['confirm', 'cancel']
});
</script>