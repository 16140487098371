import axios from 'axios';
import { getValidAuthToken } from './auth'; // Ensure you have an auth utility to get a valid auth token

const axiosInstance = axios.create({
  baseURL: 'https://aarya.api.yugin.co', // Adjust this to match your FastAPI domain
  // baseURL: 'http://127.0.0.1:8000',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Conditionally attach the Authorization header based on the `requiresAuth` flag
axiosInstance.interceptors.request.use(async (config) => {
  // Conditionally add the Authorization header
  if (config.headers.requiresAuth) {
    const authToken = await getValidAuthToken();

    if (!authToken) {
      // Redirect to login if the token is not available or expired
      
    }

    // Attach the Authorization header
    config.headers['Authorization'] = `Bearer ${authToken}`;
    delete config.headers.requiresAuth; // Remove custom header after use
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default axiosInstance;
