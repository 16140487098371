<!-- WelcomeDialog.vue -->
<template>
  <transition name="fade">
    <div v-if="show" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div :class="[
        'rounded-lg w-full max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl relative overflow-y-auto',
        'p-4 sm:p-6 md:p-8',
        isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'
      ]">
        <h2 class="text-xl sm:text-2xl md:text-3xl font-bold mb-2 sm:mb-4 text-green-500">Welcome to Aarya</h2>
        <p class="mb-2 sm:mb-4 text-sm sm:text-base">Aarya is your intelligent assistant for knowledge and productivity. Here's what you can do:</p>
        <ul class="list-disc list-inside mb-2 sm:mb-4 text-sm sm:text-base">
          <li>Ask questions on a wide range of topics</li>
          <li>Get help with coding and technical problems</li>
          <li>Brainstorm ideas for creative writing</li>
          <li>Analyze data and complex information</li>
          <li>Use web search for up-to-date information</li>
          <li>Upload and analyze PDF documents</li>
        </ul>
        <p class="mb-2 sm:mb-4 text-sm sm:text-base">Start by typing your question in the input box at the bottom of the screen.</p>
        <p class="mb-4 text-sm sm:text-base font-semibold">Web Search Feature:</p>
        <ul class="list-disc list-inside mb-4 text-sm sm:text-base">
          <li>Aarya can now search the web for up-to-date information</li>
        </ul>
        <p class="mb-4 text-sm sm:text-base font-semibold">PDF Upload Feature:</p>
        <ul class="list-disc list-inside mb-4 text-sm sm:text-base">
          <li>Click the PDF icon to open the PDF sidebar</li>
          <li>Upload your PDF files and Aarya will analyze their content</li>
          <li>Ask questions about the uploaded PDFs in your conversations</li>
        </ul>
        <button @click="closeDialog" class="custom-button w-full text-sm sm:text-base">Got it!</button>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'WelcomeDialog',
  props: {
    isDarkMode: Boolean,
  },
  setup(props, { emit }) {
    const show = ref(true);

    const closeDialog = () => {
      show.value = false;
      localStorage.setItem('welcomeDialogShown', 'true');
      emit('close');
    };

    return {
      show,
      closeDialog,
    };
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Custom scrollbar for overflow content */
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: #4CAF50 transparent;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: #4CAF50;
  border-radius: 3px;
}
</style>