<!-- WhatsNewDialog.vue -->
<template>
  <transition name="fade">
    <div v-if="show" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div :class="[
        'rounded-lg w-full max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl relative overflow-y-auto',
        'p-4 sm:p-6 md:p-8',
        isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'
      ]">
        <h2 class="text-xl sm:text-2xl md:text-3xl font-bold mb-2 sm:mb-4 text-green-500">What's New in Aarya v1.1</h2>
        
        <div class="mb-4">
          <h3 class="text-lg font-semibold mb-2">Web Search Integration</h3>
          <ul class="list-disc list-inside mb-2 text-sm sm:text-base">
            <li>Aarya can now search the web for up-to-date information</li>
          </ul>
        </div>

        <div class="mb-4">
          <h3 class="text-lg font-semibold mb-2">PDF Upload and Analysis</h3>
          <ul class="list-disc list-inside mb-2 text-sm sm:text-base">
            <li>Click the PDF icon to open the PDF sidebar</li>
            <li>Upload your PDF files and Aarya will process their content</li>
            <li>Ask questions about the uploaded PDFs in your conversations</li>
          </ul>
        </div>

        <div class="mb-4">
          <h3 class="text-lg font-semibold mb-2">Enhanced User Interface</h3>
          <ul class="list-disc list-inside mb-2 text-sm sm:text-base">
            <li>Improved sidebar navigation for easier access to conversations</li>
            <li>New dark mode toggle for comfortable viewing in low-light environments</li>
            <li>Responsive design updates for better mobile experience</li>
          </ul>
        </div>

        <button @click="closeDialog" class="custom-button w-full text-sm sm:text-base">Close</button>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'WhatsNewDialog',
  props: {
    isDarkMode: Boolean,
  },
  setup(props, { emit }) {
    const show = ref(true);

    const closeDialog = () => {
      show.value = false;
      emit('close');
    };

    return {
      show,
      closeDialog,
    };
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Custom scrollbar for overflow content */
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: #4CAF50 transparent;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: #4CAF50;
  border-radius: 3px;
}
</style>