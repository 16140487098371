<template>
    <div :class="['min-h-screen', isDarkMode ? 'bg-black text-white' : 'bg-green-50 text-black']">
        <header :class="['p-4 shadow-md', isDarkMode ? 'bg-green-800' : 'bg-green-600']">
            <div class="container mx-auto flex justify-between items-center">
                <h1 class="text-2xl font-bold text-white">Aarya AI Agent Manager</h1>
            </div>
        </header>

        <main class="container mx-auto p-4 md:p-6 lg:p-8">
            <button @click="goBack"
                :class="['mb-4 px-4 py-2 rounded-md', isDarkMode ? 'bg-green-700 text-white' : 'bg-green-500 text-white']">
                &larr; Back
            </button>

            <!-- Create/Edit Agent Form -->
            <div :class="['rounded-lg shadow-md p-6 mb-8', isDarkMode ? 'bg-gray-900' : 'bg-white']">
                <h2 class="text-xl font-semibold mb-4" :class="isDarkMode ? 'text-green-400' : 'text-green-800'">
                    {{ editingAgent ? 'Edit Agent' : 'Create New Agent' }}
                </h2>
                <form @submit.prevent="editingAgent ? updateAgent() : createAgent()" class="space-y-4">
                    <!-- Agent Image -->
                    <div class="mb-4">
                        <label class="block text-sm font-medium mb-2"
                            :class="isDarkMode ? 'text-gray-300' : 'text-gray-700'">
                            Agent Image
                        </label>
                        <div class="flex items-center space-x-4">
                            <div class="w-32 h-32 bg-gray-200 rounded-lg overflow-hidden">
                                <img v-if="imagePreview" :src="imagePreview" alt="Agent preview"
                                    class="w-full h-full object-cover" />
                                <div v-else class="w-full h-full flex items-center justify-center text-gray-400">
                                    No Image
                                </div>
                            </div>
                            <div class="flex flex-col space-y-2">
                                <input type="file" @change="handleImageUpload" accept="image/*" class="hidden"
                                    ref="fileInput" />
                                <button type="button" @click="$refs.fileInput.click()"
                                    :class="['px-4 py-2 rounded-md', isDarkMode ? 'bg-green-700 text-white hover:bg-green-600' : 'bg-green-500 text-white hover:bg-green-400']">
                                    Upload Image
                                </button>
                                <button type="button" @click="generateImage" :disabled="!newAgent.name"
                                    :class="['px-4 py-2 rounded-md',
                                        isDarkMode
                                            ? 'bg-blue-700 text-white hover:bg-blue-600 disabled:bg-blue-900 disabled:cursor-not-allowed'
                                            : 'bg-blue-500 text-white hover:bg-blue-400 disabled:bg-blue-300 disabled:cursor-not-allowed']">
                                    Generate Image
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- Agent Name -->
                    <div class="relative">
                        <label for="agentName" class="block text-sm font-medium mb-2"
                            :class="isDarkMode ? 'text-gray-300' : 'text-gray-700'">Agent Name</label>
                        <div class="flex items-center">
                            <input v-model="newAgent.name" id="agentName" type="text" required
                                :class="['block w-full rounded-md', isDarkMode ? 'bg-gray-800 text-white border-gray-700' : 'bg-white text-black border-gray-300']" />
                            <button @click="enhanceField('name')" type="button" class="ml-2"
                                :disabled="loadingFields.name">
                                <StarEnhanceIcon :color="isDarkMode ? '#4CAF50' : '#2E7D32'"
                                    :loading="loadingFields.name" />
                            </button>
                        </div>
                    </div>

                    <!-- Agent Description -->
                    <div class="relative">
                        <label for="agentDescription" class="block text-sm font-medium mb-2"
                            :class="isDarkMode ? 'text-gray-300' : 'text-gray-700'">Description</label>
                        <div class="flex items-center">
                            <textarea v-model="newAgent.description" id="agentDescription" rows="3" required
                                :class="['block w-full rounded-md', isDarkMode ? 'bg-gray-800 text-white border-gray-700' : 'bg-white text-black border-gray-300']"></textarea>
                            <button @click="enhanceField('description')" type="button" class="ml-2"
                                :disabled="loadingFields.description">
                                <StarEnhanceIcon :color="isDarkMode ? '#4CAF50' : '#2E7D32'"
                                    :loading="loadingFields.description" />
                            </button>
                        </div>
                    </div>

                    <!-- Agent Instructions -->
                    <div class="relative">
                        <label for="agentInstructions" class="block text-sm font-medium mb-2"
                            :class="isDarkMode ? 'text-gray-300' : 'text-gray-700'">Instructions</label>
                        <div class="flex items-center">
                            <textarea v-model="newAgent.instructions" id="agentInstructions" rows="4" required
                                :class="['block w-full rounded-md', isDarkMode ? 'bg-gray-800 text-white border-gray-700' : 'bg-white text-black border-gray-300']"></textarea>
                            <button @click="enhanceField('instructions')" type="button" class="ml-2"
                                :disabled="loadingFields.instructions">
                                <StarEnhanceIcon :color="isDarkMode ? '#4CAF50' : '#2E7D32'"
                                    :loading="loadingFields.instructions" />
                            </button>
                        </div>
                    </div>


                    <!-- Tools Section -->
                    <div>
                        <h3 class="text-lg font-semibold mb-2"
                            :class="isDarkMode ? 'text-green-400' : 'text-green-700'">Tools</h3>
                        <div class="flex flex-wrap gap-2 mb-2">
                            <div v-for="tool in selectedTools" :key="tool.id"
                                class="bg-green-100 text-green-800 px-2 py-1 rounded-full text-sm flex items-center">
                                {{ tool.name }}
                                <button @click="removeSelectedTool(tool)"
                                    class="ml-1 text-green-600 hover:text-green-800">
                                    &times;
                                </button>
                            </div>
                        </div>
                        <select @change="addSelectedTool" class="w-full p-2 border rounded"
                            :class="isDarkMode ? 'bg-gray-800 text-white border-gray-700' : 'bg-white text-black border-gray-300'">
                            <option value="">Select a tool</option>
                            <option v-for="tool in availableTools" :key="tool.id" :value="tool.id">
                                {{ tool.name }} - {{ tool.description }}
                            </option>
                        </select>
                    </div>

                    <!-- Knowledge Base Section -->
                    <div>
                        <h3 class="text-lg font-semibold mb-2"
                            :class="isDarkMode ? 'text-green-400' : 'text-green-700'">
                            Knowledge Base</h3>
                        <div class="space-y-4">
                            <div>
                                <label for="fileUpload" class="block text-sm font-medium"
                                    :class="isDarkMode ? 'text-gray-300' : 'text-gray-700'">Upload File (PDF or
                                    TXT)</label>
                                <input @change="handleFileUpload" type="file" id="fileUpload" accept=".pdf,.txt"
                                    :class="['mt-1 block w-full', isDarkMode ? 'text-gray-300' : 'text-gray-700']" />
                            </div>
                            <!-- <div>
                                <label for="urlInput" class="block text-sm font-medium"
                                    :class="isDarkMode ? 'text-gray-300' : 'text-gray-700'">Add URL</label>
                                <div class="flex">
                                    <input v-model="newUrl" type="url" id="urlInput" placeholder="https://example.com"
                                        :class="['flex-grow mt-1 rounded-l-md', isDarkMode ? 'bg-gray-800 text-white border-gray-700' : 'bg-white text-black border-gray-300']" />
                                    <button @click="addUrl"
                                        :class="['mt-1 px-4 py-2 rounded-r-md', isDarkMode ? 'bg-green-700 text-white hover:bg-green-600' : 'bg-green-500 text-white hover:bg-green-400']">
                                        Add
                                    </button>
                                </div>
                            </div> -->
                            <button @click="openTextContentModal"
                                :class="['px-4 py-2 rounded-md', isDarkMode ? 'bg-green-700 text-white hover:bg-green-600' : 'bg-green-500 text-white hover:bg-green-400']">
                                Add Text Content
                            </button>
                            <ul class="space-y-2">
                                <li v-for="item in knowledgeBase" :key="item._id"
                                    :class="['flex justify-between items-center p-2 rounded', isDarkMode ? 'bg-gray-800' : 'bg-green-50']">
                                    <div>
                                        <span v-for="fileItem in item.items" :key="fileItem.id">
                                            {{ fileItem.filename }}
                                            <button @click="removeKnowledgeBaseItem(fileItem.id)"
                                                :class="isDarkMode ? 'text-red-400 hover:text-red-300' : 'text-red-600 hover:text-red-500'">
                                                <Trash2Icon class="h-5 w-5" />
                                            </button>
                                        </span>
                                    </div>
                                </li>
                            </ul>

                            <ul class="space-y-2">
                                <li v-for="(url, index) in newAgent.knowledge_base_urls" :key="index"
                                    :class="['flex justify-between items-center p-2 rounded', isDarkMode ? 'bg-gray-800' : 'bg-green-50']">
                                    <span>{{ url.value }}</span>
                                    <button @click="removeUrl(index)"
                                        :class="isDarkMode ? 'text-red-400 hover:text-red-300' : 'text-red-600 hover:text-red-500'">
                                        <Trash2Icon class="h-5 w-5" />
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <button type="submit"
                        :class="['w-full py-2 px-4 rounded-md', isDarkMode ? 'bg-green-700 text-white hover:bg-green-600' : 'bg-green-600 text-white hover:bg-green-500']">
                        {{ editingAgent ? 'Update Agent' : 'Create Agent' }}
                    </button>
                </form>
            </div>

            <!-- Existing List of Agents Section -->
            <div :class="['rounded-lg shadow-md p-6', isDarkMode ? 'bg-gray-900' : 'bg-white']">
                <h2 class="text-xl font-semibold mb-4" :class="isDarkMode ? 'text-green-400' : 'text-green-800'">Your
                    Agents</h2>

                <!-- Added Classes for Scrollable List -->
                <div style="max-height: 400px; overflow-y: auto;">
                    <transition-group name="list" tag="ul" class="space-y-4">
                        <li v-for="agent in agents" :key="agent._id"
                            :class="['rounded-lg p-4 shadow-sm transition duration-300 ease-in-out hover:shadow-md', isDarkMode ? 'bg-gray-800' : 'bg-green-50']">
                            <div class="flex items-center justify-between">
                                <h3 class="text-lg font-medium"
                                    :class="isDarkMode ? 'text-green-400' : 'text-green-800'">{{ agent.name }}</h3>
                                <div class="space-x-2">
                                    <button @click="editAgent(agent)"
                                        :class="['focus:outline-none transition duration-300 ease-in-out', isDarkMode ? 'text-green-400 hover:text-green-300' : 'text-green-600 hover:text-green-500']">
                                        <EditIcon class="h-5 w-5" />
                                    </button>
                                    <button @click="deleteAgent(agent._id)"
                                        :class="['focus:outline-none transition duration-300 ease-in-out', isDarkMode ? 'text-red-400 hover:text-red-300' : 'text-red-600 hover:text-red-500']">
                                        <Trash2Icon class="h-5 w-5" />
                                    </button>
                                </div>
                            </div>
                            <p :class="['mt-2', isDarkMode ? 'text-gray-400' : 'text-gray-600']">{{ agent.description }}
                            </p>
                        </li>
                    </transition-group>
                    <div v-if="agents.length === 0" class="text-center py-8">
                        <RobotIcon
                            :class="['h-16 w-16 mx-auto mb-4', isDarkMode ? 'text-green-700' : 'text-green-300']" />
                        <p :class="isDarkMode ? 'text-gray-400' : 'text-gray-500'" class="text-lg">No agents created
                            yet. Start by creating your first AI agent!</p>
                    </div>
                </div>
            </div>
        </main>

        <!-- Text Content Modal -->
        <transition name="modal">
            <div v-if="showTextContentModal"
                class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                <div :class="['rounded-lg shadow-xl p-6 w-full max-w-md', isDarkMode ? 'bg-gray-900' : 'bg-white']">
                    <h3 class="text-lg font-semibold mb-4" :class="isDarkMode ? 'text-green-400' : 'text-green-800'">Add
                        Text Content</h3>
                    <div class="mb-4">
                        <label for="textTitle" class="block text-sm font-medium"
                            :class="isDarkMode ? 'text-gray-300' : 'text-gray-700'">Title</label>
                        <input v-model="newTextTitle" type="text" id="textTitle"
                            :class="['mt-1 block w-full rounded-md', isDarkMode ? 'bg-gray-800 text-white border-gray-700' : 'bg-white text-black border-gray-300']" />
                    </div>
                    <div class="mb-4">
                        <label for="textContent" class="block text-sm font-medium"
                            :class="isDarkMode ? 'text-gray-300' : 'text-gray-700'">Content</label>
                        <textarea v-model="newTextContent" id="textContent" rows="5"
                            :class="['mt-1 block w-full rounded-md', isDarkMode ? 'bg-gray-800 text-white border-gray-700' : 'bg-white text-black border-gray-300']"></textarea>
                    </div>
                    <div class="flex justify-end space-x-2">
                        <button @click="closeTextContentModal"
                            :class="['px-4 py-2 rounded-md', isDarkMode ? 'bg-gray-700 text-white hover:bg-gray-600' : 'bg-gray-200 text-gray-800 hover:bg-gray-300']">
                            Cancel
                        </button>
                        <button @click="addTextContent"
                            :class="['px-4 py-2 rounded-md', isDarkMode ? 'bg-green-700 text-white hover:bg-green-600' : 'bg-green-500 text-white hover:bg-green-400']">
                            Add
                        </button>
                    </div>
                </div>
            </div>
        </transition>

        <!-- Upload Progress Modal -->
        <transition name="modal">
            <div v-if="uploadStatus" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                <div :class="['rounded-lg shadow-xl p-6 w-full max-w-md', isDarkMode ? 'bg-gray-900' : 'bg-white']">
                    <h3 class="text-lg font-semibold mb-4" :class="isDarkMode ? 'text-green-400' : 'text-green-800'">
                        Upload Progress</h3>
                    <div class="mb-4">
                        <div class="w-full bg-gray-200 rounded-full h-2.5"
                            :class="isDarkMode ? 'bg-gray-700' : 'bg-gray-200'">
                            <div class="bg-green-600 h-2.5 rounded-full"
                                :style="{ width: `${uploadStatus.progress * 100}%` }"></div>
                        </div>
                    </div>
                    <p :class="isDarkMode ? 'text-gray-300' : 'text-gray-700'">{{ uploadStatus.message }}</p>
                </div>
            </div>
        </transition>
    </div>
</template>
<script setup>
import { ref, onMounted, reactive, computed, watch } from 'vue'
import axios from '../axios'
import { EditIcon, Trash2Icon, RobotIcon } from 'lucide-vue-next'
import { useRouter } from 'vue-router'
import StarEnhanceIcon from '@/components/AiIcon.vue'


const agents = ref([])
const newAgent = ref({
    name: '',
    description: '',
    instructions: '',
    allowed_tools: [],
    knowledge_base_urls: [],
    image: null,
    image_url: null
})
const editingAgent = ref(null)
const knowledgeBase = ref([])
// const newUrl = ref('')
const newTextTitle = ref('')
const newTextContent = ref('')
const uploadStatus = ref(null)
const showTextContentModal = ref(false)
const selectedAgentId = ref(null)
const allTools = ref([])
const router = useRouter()
const isDarkMode = ref(localStorage.getItem('darkMode') === 'true')
const imagePreview = ref(null)
const fileInput = ref(null)
const selectedTools = ref([])

onMounted(() => {
    fetchTools()
    fetchAgents()
    resetForm()
    checkDarkMode()
})

const handleImageUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
            imagePreview.value = e.target.result
        }
        reader.readAsDataURL(file)
        newAgent.value.image = file
    }
}

const generateImage = async () => {
    if (!newAgent.value.name) {
        alert("Please provide a name for the agent before generating an image.");
        return;
    }

    let prompt = `Generate an image for an AI agent named "${newAgent.value.name}"`;
    if (newAgent.value.description) {
        prompt += ` with the following description: ${newAgent.value.description}`;
    }

    // Add validation for prompt length or content if needed
    if (prompt.length > 500) {  // Example max length
        alert("The description is too long. Please shorten it.");
        return;
    }

    try {
        const response = await axios.post('/generate-agent-image',
            { prompt },
            { headers: { requiresAuth: true } }
        );
        imagePreview.value = response.data.image_url;
        newAgent.value.image_url = response.data.image_url;
    } catch (error) {
        console.error('Error generating image:', error);
        alert("Failed to generate image. Please try again.");
    }
};

const resetForm = () => {
    newAgent.value = {
        name: '',
        description: '',
        instructions: '',
        allowed_tools: [],
        knowledge_base_urls: [],
        image: null,
        image_url: null
    }
    editingAgent.value = null
    selectedAgentId.value = null
    selectedTools.value = []
    knowledgeBase.value = []
    imagePreview.value = null
}

const goBack = () => {
    router.go(-1)
}

const checkDarkMode = () => {
    if (localStorage.getItem('isDarkMode') === 'true') {
        isDarkMode.value = true
    }
}

const fetchAgents = async () => {
    try {
        const response = await axios.get('/agents', {
            headers: { requiresAuth: true }
        });
        agents.value = response.data.agents;
    } catch (error) {
        console.error('Error fetching agents:', error);
    }
}

const createAgent = async () => {
    try {
        const formData = new FormData();
        formData.append('name', newAgent.value.name);
        formData.append('description', newAgent.value.description);
        formData.append('instructions', newAgent.value.instructions);
        const toolIds = selectedTools.value.map(tool => tool.id);
        formData.append('allowed_tools', JSON.stringify(toolIds));

        if (newAgent.value.image) {
            formData.append('image', newAgent.value.image);
        } else if (newAgent.value.image_url) {
            formData.append('image_url', newAgent.value.image_url);
        }

        const response = await axios.post('/agents/create', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                requiresAuth: true
            }
        });

        const newAgentId = response.data.agent_id; // Assuming the API returns the new agent's ID

        // Handle knowledge base and URLs
        await updateKnowledgeBase(newAgentId);
        // await updateUrls(newAgentId);

        await fetchAgents();
        resetForm();
    } catch (error) {
        console.error('Error creating agent:', error.response?.data || error.message);
    }
};
const editAgent = async (agent) => {
    selectedAgentId.value = agent._id;
    editingAgent.value = agent;

    if (allTools.value.length === 0) {
        await fetchTools();
    }

    // Populate newAgent with the existing agent data
    newAgent.value = {
        name: agent.name,
        description: agent.description,
        instructions: agent.instructions,
        allowed_tools: agent.allowed_tools || [],
        knowledge_base_urls: agent.knowledge_base_urls || [],
        image: null,
        image_url: agent.image_url
    };

    // Set image preview if there's an image_url
    imagePreview.value = agent.image_url;

    selectedTools.value = (agent.allowed_tools || [])
        .map(toolId => {
            const found = allTools.value.find(t => t.id === toolId);
            return found;
        })
        .filter(Boolean);

    await fetchKnowledgeBase();
};

const updateAgent = async () => {
    try {
        const formData = new FormData();
        formData.append('name', newAgent.value.name);
        formData.append('description', newAgent.value.description);
        formData.append('instructions', newAgent.value.instructions);
        const toolIds = selectedTools.value.map(tool => tool.id);
        formData.append('allowed_tools', JSON.stringify(toolIds));

        if (newAgent.value.image) {
            formData.append('image', newAgent.value.image);
        } else if (newAgent.value.image_url) {
            formData.append('image_url', newAgent.value.image_url);
        }

        await axios.put(`/agents/${selectedAgentId.value}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                requiresAuth: true
            }
        });

        // Handle knowledge base and URLs
        await updateKnowledgeBase(selectedAgentId.value);
        await updateUrls(selectedAgentId.value);

        await fetchAgents();
        resetForm();
    } catch (error) {
        console.error('Error updating agent:', error.response?.data || error.message);
    }
};

const updateKnowledgeBase = async (agentId) => {
    for (const item of knowledgeBase.value) {
        if (item.isNew) {
            const formData = new FormData();
            formData.append('file', item.file);
            try {
                await axios.post(`/agents/${agentId}/knowledge-base`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        requiresAuth: true
                    }
                });
            } catch (error) {
                console.error('Error uploading knowledge base item:', error);
            }
        }
    }
};

const updateUrls = async (agentId) => {
    const newUrls = newAgent.value.knowledge_base_urls.filter(url => url.isNew);
    if (newUrls.length > 0) {
        try {
            await axios.post(`/agents/${agentId}/knowledge-base`, {
                urls: newUrls.map(url => url.value)
            }, {
                headers: { requiresAuth: true }
            });
        } catch (error) {
            console.error('Error adding URLs:', error);
        }
    }
};

watch(selectedTools, () => {
}, { deep: true });

const deleteAgent = async (agentId) => {
    if (confirm('Are you sure you want to delete this agent?')) {
        try {
            await axios.delete(`/agents/${agentId}`, {
                headers: { requiresAuth: true }
            })
            await fetchAgents()
        } catch (error) {
            console.error('Error deleting agent:', error)
        }
    }
}


// Compute available tools
const availableTools = computed(() => {
    return allTools.value.filter(tool =>
        !selectedTools.value.some(selectedTool => selectedTool.id === tool.id)
    );
});

// Function to add a selected tool
const addSelectedTool = (event) => {
    const toolId = event.target.value;
    if (toolId) {
        const tool = allTools.value.find(t => t.id === toolId);
        if (tool) {
            selectedTools.value.push(tool);
        }
        event.target.value = '';
    }
};

// Function to remove a selected tool
const removeSelectedTool = (toolToRemove) => {
    selectedTools.value = selectedTools.value.filter(tool => tool.id !== toolToRemove.id)
}


const fetchKnowledgeBase = async () => {
    if (!selectedAgentId.value) return;
    try {
        const response = await axios.get(`/agents/${selectedAgentId.value}/knowledge-base`, {
            headers: { requiresAuth: true }
        });
        knowledgeBase.value = response.data.knowledge_base.map(item => ({
            ...item,
            isNew: false,
            content: item.filename || item.url || 'Text content'  // Add this line
        }));
        console.log('Fetched knowledge base:', knowledgeBase.value);
    } catch (error) {
        console.error('Error fetching knowledge base:', error);
        knowledgeBase.value = [];
    }
};

const handleFileUpload = (event) => {
    const files = event.target.files;
    if (files) {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            knowledgeBase.value.push({
                id: Date.now() + i, // Temporary ID
                content: file.name,
                file: file,
                isNew: true
            });
        }
    }
};
const monitorUploadProgress = async (uploadId) => {
    const eventSource = new EventSource(`/upload_status_stream/${uploadId}`)

    eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data)
        uploadStatus.value = data

        if (data.status === 'completed' || data.status === 'error') {
            eventSource.close()
            uploadStatus.value = null
            fetchKnowledgeBase()
        }
    }

    eventSource.onerror = (error) => {
        console.error('Error in upload status stream:', error)
        eventSource.close()
        uploadStatus.value = null
    }
}

// const addUrl = () => {
//     if (newUrl.value) {
//         newAgent.value.knowledge_base_urls.push({
//             value: newUrl.value,
//             isNew: true
//         });
//         newUrl.value = '';
//     }
// };

const removeKnowledgeBaseItem = async (itemId) => {
    if (!selectedAgentId.value) return;
    try {
        await axios.delete(`/agents/${selectedAgentId.value}/knowledge-base/${itemId}`, {
            headers: { requiresAuth: true }
        });
        knowledgeBase.value = knowledgeBase.value.map(item => ({
            ...item,
            items: item.items.filter(fileItem => fileItem.id !== itemId)
        })).filter(item => item.items.length > 0);
    } catch (error) {
        console.error('Error deleting knowledge base item:', error);
    }
};

const removeUrl = (index) => {
    newAgent.value.knowledge_base_urls.splice(index, 1);
};

const openTextContentModal = () => {
    showTextContentModal.value = true
}

const closeTextContentModal = () => {
    showTextContentModal.value = false
    newTextTitle.value = ''
    newTextContent.value = ''
}

const addTextContent = async () => {
    if (!newTextTitle.value || !newTextContent.value || !selectedAgentId.value) {
        alert('Please select an agent and enter both title and content')
        return
    }
    const blob = new Blob([newTextContent.value], { type: 'text/plain' })
    const file = new File([blob], `${newTextTitle.value}.txt`, { type: 'text/plain' })

    const formData = new FormData()
    formData.append('file', file)

    try {
        const response = await axios.post(`/agents/${selectedAgentId.value}/knowledge-base`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                requiresAuth: true
            }
        })

        if (response.data.upload_id) {
            monitorUploadProgress(response.data.upload_id)
        } else {
            await fetchKnowledgeBase()
        }

        closeTextContentModal()
    } catch (error) {
        console.error('Error adding text content:', error)
    }
}

// const deleteKnowledgeBaseItem = async (itemId) => {
//     if (!selectedAgentId.value) return

//     try {
//         await axios.delete(`/agents/${selectedAgentId.value}/knowledge-base/${itemId}`, {
//             headers: { requiresAuth: true }
//         })
//         await fetchKnowledgeBase()
//     } catch (error) {
//         console.error('Error deleting knowledge base item:', error)
//     }
// }

const fetchTools = async () => {
    try {
        const response = await axios.get('/tools', {
            headers: { requiresAuth: true }
        });

        if (Array.isArray(response.data.tools)) {
            allTools.value = response.data.tools;
            return allTools.value;
        } else {
            console.error('Unexpected tools data structure:', response.data);
            allTools.value = [];
            return [];
        }
    } catch (error) {
        console.error('Error fetching tools:', error);
        allTools.value = [];
        return [];
    }
};




const loadingFields = reactive({
    name: false,
    description: false,
    instructions: false
})

const enhanceField = async (field) => {
    loadingFields[field] = true
    try {
        const response = await axios.post('/enhance-agent-field', {
            field: field,
            content: newAgent.value[field]
        }, {
            headers: { requiresAuth: true }
        })
        newAgent.value[field] = response.data.enhanced_content
    } catch (error) {
        console.error(`Error enhancing ${field}:`, error)
    } finally {
        loadingFields[field] = false
    }
}


</script>

<style scoped>
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateY(30px);
}

.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
    opacity: 0;
}

.agents-list {
    max-height: 400px;
    overflow-y: auto;
}
</style>